import { endPoints } from "../../services/EndPoints";
import { _getRdy } from "../../services/HttpMethods";
import { setSportsDetails } from "../../utils/common";
import { ACTIONTYPES } from "./actionTypes";

export const fetchSports = (token) => async (dispatch) => {
  try {
    const response = await _getRdy(endPoints.sports, token);
    const { msg, status, data = {} } = response;
    if (status === 200) {
      dispatch({
        type: ACTIONTYPES.SPORTS_DETAILS_SUCCESS,
        payload: data,
      });

      const payload = {
        sportsDetails: data,
      };
      setSportsDetails(payload);
    } else {
      dispatch({
        type: ACTIONTYPES.SPORTS_DETAILS_FAIL,
        payload: msg,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTIONTYPES.SPORTS_DETAILS_FAIL,
      payload: error,
    });
  }
};
