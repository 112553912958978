const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const currentDate = new Date();
const dates = [];

for (let i = 0; i <= 7; i++) {
  const pastDate = new Date(currentDate);
  pastDate.setDate(currentDate.getDate() - i);
  dates.push(formatDate(pastDate));
}

function convertTimestampToDate(inputDate) {
  const parts = inputDate?.split("-");

  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  return `${day}/${month}/${year}`;
}

export const weeklyReportTableColumns = [
  {
    id: "Sport",
    numeric: false,
    disablePadding: true,
    label: "Sport",
    width: "3%",
  },
  {
    id: "EVENT",
    numeric: false,
    disablePadding: true,
    label: `${dates[1]}`,
    width: "20%",
  },
  {
    id: "COMM IN",
    numeric: false,
    disablePadding: true,
    label: `${dates[2]}`,
    width: "20%",
  },
  {
    id: "COMM OUT",
    numeric: false,
    disablePadding: true,
    label: `${dates[3]}`,
    width: "20%",
  },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: true,
    label: `${dates[4]}`,
    width: "20%",
  },
  {
    id: "TOTAL",
    numeric: false,
    disablePadding: true,
    label: `${dates[5]}`,
    width: "9%",
  },
  {
    id: "DATE_5",
    numeric: false,
    disablePadding: true,
    label: `${dates[6]}`,
    width: "9%",
  },
  {
    id: "DATE_6",
    numeric: false,
    disablePadding: true,
    label: `${dates[7]}`,
    width: "9%",
  },
  {
    id: "TOTAL",
    numeric: false,
    disablePadding: true,
    label: `Total`,
    width: "9%",
  },
];

export const config = {
  eventType: {
    field: "eventType",
    value: (item) => item?.sport,
    type: "value",
    style: { fontWeight: "bold" },
  },
  [dates[1]]: {
    field: "amount",
    type: "value",
    value: (value) => {
      const data = value?.data?.find(
        (item) => convertTimestampToDate(item?.date) === dates[1]
      );
      return data?.amount;
    },
  },
  [dates[2]]: {
    field: "amount",
    type: "value",
    value: (value) => {
      const data = value?.data?.find(
        (item) => convertTimestampToDate(item?.date) === dates[2]
      );
      return data?.amount;
    },
  },
  [dates[3]]: {
    field: "amount",
    type: "value",
    value: (value) => {
      const data = value?.data?.find(
        (item) => convertTimestampToDate(item?.date) === dates[3]
      );
      return data?.amount;
    },
  },
  [dates[4]]: {
    field: "amount",
    type: "value",
    value: (value) => {
      const data = value?.data?.find(
        (item) => convertTimestampToDate(item?.date) === dates[4]
      );
      return data?.amount;
    },
  },
  [dates[5]]: {
    field: "amount",
    type: "value",
    value: (value) => {
      const data = value?.data?.find(
        (item) => convertTimestampToDate(item?.date) === dates[5]
      );
      return data?.amount;
    },
  },
  [dates[6]]: {
    field: "amount",
    type: "value",
    value: (value) => {
      const data = value?.data?.find(
        (item) => convertTimestampToDate(item?.date) === dates[6]
      );
      return data?.amount;
    },
  },
  [dates[7]]: {
    field: "amount",
    type: "value",
    value: (value) => {
      const data = value?.data?.find(
        (item) => convertTimestampToDate(item?.date) === dates[7]
      );
      return data?.amount;
    },
  },
  Total: {
    field: "Total",
    type: "value",
    value: (item) => item?.total,
  },
};
