import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import ErrorField from "../../components/ErrorField";
import { error } from "../../constants/errors";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import AnimatedInputField from "../../components/AnimatedInputField";
import { constants } from "../../constants/constants";
import { useUser } from "../../hooks/user-hook";

const CreateUserForm = () => {
  const role = localStorage.getItem("role");
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Role: "",
      Parent: "",
      Username: "",
      Name: "",
      Password: "",
      ConfirmPassword: "",
      MobileNumber: "",
      Share: "",
    },
  });

  console.log(errors, "errors");

  const { getSupermasterOptions, supermasterOption, createUser } = useUser();
  useEffect(() => {
    if (watch("Role") === "Master") getSupermasterOptions();
  }, [watch("Role")]);

  const onSubmit = (data) => {
    createUser(data);
    reset({
      Role: "",
      Parent: "",
      Username: "",
      Name: "",
      Password: "",
      ConfirmPassword: "",
      MobileNumber: "",
      Share: "",
    });
  };

  return (
    <form className="m-2 bg-white rounded-md" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 m-2">
        <div className="p-4 bg-white grid">
          <Controller
            name="Role"
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id="role-select">Role</InputLabel>
                <Select
                  labelId="role-select"
                  id="demo-simple-select"
                  label="Role"
                  {...(register &&
                    register("Role", {
                      required: error.roleRequired,
                    }))}
                  {...field}
                >
                  <MenuItem disabled={role === "3"} value={"Supermaster"}>
                    Supermaster
                  </MenuItem>
                  <MenuItem value={"Master"}>Master</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {errors.Role && <ErrorField error={errors.Role.message} />}
        </div>
        {watch("Role") === "Master" && role !== "3" && (
          <div className="p-4 bg-white grid">
            <Controller
              name="Parent"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="parent-select">Parent</InputLabel>
                  <Select
                    labelId="parent-select"
                    id="demo-simple-select"
                    label="Parent"
                    {...(register &&
                      register("Parent", {
                        required: error.parentRequired,
                      }))}
                    {...field}
                  >
                    {supermasterOption.map((item, indx) => {
                      return (
                        <MenuItem value={item.id} key={indx + item.username}>
                          {item.username}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
            {errors.User && <ErrorField error={errors.User.message} />}
          </div>
        )}

        <div className="p-4">
          <Controller
            name="Username"
            control={control}
            render={({ field }) => (
              <AnimatedInputField
                label="Username"
                type="text"
                register={register}
                validation={{ required: error.usernameRequired }}
                errorCheck={errors.Username}
                {...field}
              />
            )}
          />
          {errors.Username && <ErrorField error={errors.Username.message} />}
        </div>
        <div className="p-4">
          <Controller
            name="Name"
            control={control}
            render={({ field }) => (
              <AnimatedInputField
                label="Name"
                type="text"
                register={register}
                validation={{ required: error.nameRequired }}
                errorCheck={errors.Name}
                {...field}
              />
            )}
          />
          {errors.Name && <ErrorField error={errors.Name.message} />}
        </div>
        <div className="p-4">
          <Controller
            name="Password"
            control={control}
            render={({ field }) => (
              <AnimatedInputField
                label="Password"
                type="password"
                register={register}
                validation={{ required: error.passwordRequired }}
                errorCheck={errors.Password}
                {...field}
              />
            )}
          />
          {errors.Password && <ErrorField error={errors.Password.message} />}
        </div>
        <div className="p-4">
          <Controller
            name="ConfirmPassword"
            control={control}
            render={({ field }) => (
              <AnimatedInputField
                label="ConfirmPassword"
                type="password"
                register={register}
                validation={{
                  required: "Confirm password is required",
                  validate: (value) =>
                    value === watch("Password") || "Passwords do not match",
                }}
                errorCheck={errors.ConfirmPassword}
                {...field}
              />
            )}
          />
          {errors.ConfirmPassword && (
            <ErrorField error={errors.ConfirmPassword.message} />
          )}
        </div>
        <div className="p-4">
          <Controller
            name="MobileNumber"
            control={control}
            render={({ field }) => (
              <AnimatedInputField
                label="MobileNumber"
                type="text"
                register={register}
                errorCheck={errors.MobileNumber}
                {...field}
              />
            )}
          />
        </div>
        <div className="p-4">
          <Controller
            name="Share"
            control={control}
            render={({ field }) => (
              <AnimatedInputField
                label="Share"
                type="text"
                register={register}
                validation={{ required: error.shareRequired }}
                errorCheck={errors.Share}
                {...field}
              />
            )}
          />
          {errors.Share && <ErrorField error={errors.Share.message} />}
        </div>
      </div>
      <div className="w-[10%] rounded-md">
        <button className="p-2 bg-[#CC9647] text-white m-[25px]">
          {constants.SUBMIT}
        </button>
      </div>
    </form>
  );
};

export default CreateUserForm;
