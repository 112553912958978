import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { constants } from "../../constants/constants";
import CustomDatePicker from "../../components/CustomDatePicker";

const SearchInputs = ({
  field,
  label,
  value,
  onChange,
  optionArr,
  id,
  variant,
  name,
  showIcon,
  disable = false
}) => {
  const renderInput = () => {
    switch (field) {
      case constants.textField:
        return (
          <TextField
            id="standard-basic"
            label={label}
            variant="standard"
            value={value}
            onChange={onChange}
            fullWidth
            name={name}
            disabled={disable}
          />
        );
      case constants.selectField:
        return (
          <FormControl fullWidth variant={variant}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
              labelId={id}
              id="demo-simple-select"
              value={value}
              label={label}
              onChange={onChange}
            >
              {optionArr?.map((item, indx) => (
                <MenuItem key={indx} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case constants.dateField:
        return (
          <CustomDatePicker
            label={label}
            date={value}
            onChange={onChange}
            showIcon={showIcon}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      component="form"
      sx={{
        width: "100%",
      }}
      noValidate
      autoComplete="off"
    >
      {renderInput()}
    </Box>
  );
};

export default SearchInputs;
