export const settlementTableColumns = [
    {
      id: "no",
      numeric: true,
      disablePadding: true,
      label: "NO",
      // width: "20%",
    },
    {
      id: "desc",
      numeric: false,
      disablePadding: true,
      label: "DESC",
      // width: "1%",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "TYPE",
      // width: "1%",
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: true,
      label: "AMOUNT",
      // width: "1%",
    },
    {
      id: "total",
      numeric: true,
      disablePadding: true,
      label: "TOTAL",
      // width: "1%",
    },
    {
      id: "dc",
      numeric: true,
      disablePadding: true,
      label: "D/C",
      // width: "15%",
    },
    {
      id: "note",
      numeric: true,
      disablePadding: true,
      label: "NOTE",
      // width: "1%",
    },
    {
      id: "timeDate",
      numeric: false,
      disablePadding: true,
      label: "TIME/DATE",
      // width: "1%",
    },
  ];