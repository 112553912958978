import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ date, onChange, label, showIcon = true }) => {
  return (
    <div>
      <label className="block text-gray-700 text-sm font-bold">{label}</label>
      <DatePicker
        toggleCalendarOnIconClick
        showIcon={showIcon}
        selected={date}
        onChange={onChange}
        dateFormat="MMMM d, yyyy"
        popperClassName="z-10"
      />
    </div>
  );
};

export default CustomDatePicker;
