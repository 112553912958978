import React, { useEffect, useRef, useState } from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import { Grid2, Button, Container } from "@mui/material";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import EnhancedTable from "../../containers/CustomTable/CustomTable";
import { clientsTableColumns, config } from "./constant";
import ProfitLossFilter from "./ProfitLossFilter";
import useProfitLoss from "../../hooks/profitLoss-hook";
import { getFormattedData } from "../../utils/hookUtils";
import { useLocation } from "react-router-dom";

const ProfileLoss = () => {
  const name = localStorage.getItem("userName");
  const location = useLocation();
  const isMounted = useRef(true);
  const {
    getProfitLoss,
    profitLoss,
    profitLossFilters,
    handleSearch,
    disableBtn,
    handleFilterChanges,
    getAccountStatementForClient,
  } = useProfitLoss(location);
  const formattedProfitLossData = getFormattedData(profitLoss, config);

  useEffect(() => {
    if (isMounted.current) {
      if (location?.state?.arr) {
        getAccountStatementForClient(location?.state?.arr, profitLossFilters);
      } else getProfitLoss(profitLossFilters);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Layout heading={`Profit Loss[${name}]`} marqueeText={commonMarqueeContent}>
      <Container disableGutters maxWidth={false}>
        <ProfitLossFilter
          profitLossFilters={profitLossFilters}
          handleSearch={handleSearch}
          disableBtn={disableBtn}
          handleFilterChanges={handleFilterChanges}
        />
        <EnhancedTable
          tableColumns={clientsTableColumns}
          tableData={formattedProfitLossData}
        />
      </Container>
    </Layout>
  );
};

export default ProfileLoss;
