import { GET_CLIENT_BETS, GET_USER_BETS } from "./actionType";

const initialState = {
  clientBets: [],
  userBets: [],
};

const betReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_BETS:
      return {
        ...state,
        clientBets: action.payload,
      };
    case GET_USER_BETS:
      return {
        ...state,
        userBets: action.payload,
      };
    default:
      return state;
  }
};

export default betReducer;
