import React from "react";
import { commonMarqueeContent } from "../../constants/constants";
import Layout from "../../layout/Layout";
import ChangePasswordSection from "./ChangePasswordSection";
import { Card, Grid2, Typography } from "@mui/material";

function ChangePassword() {
  return (
    <Layout heading="Profile" marqueeText={commonMarqueeContent}>
      <>
        <ChangePasswordSection />
      </>
    </Layout>
  );
}

export default ChangePassword;
