import React from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import SearchClients from "../Clients/SearchClients";
import CustomTable from "../../containers/CustomTable/CustomTable";
import { supermasterTableColumns } from "../Supermaster/constants";
import { useMaster } from "../../hooks/master-hook";
import { useLocation } from "react-router-dom";

const Masters = () => {
  const name = localStorage.getItem("userName");
  const location = useLocation();
  const {
    filters,
    handleChangeFilter,
    handleSearch,
    disableBtn,
    formattedMasterData,
  } = useMaster(location?.state?.arr);
  return (
    <Layout heading={name} marqueeText={`${commonMarqueeContent}`}>
      <SearchClients
        filters={filters}
        handleChangeFilter={handleChangeFilter}
        handleSearch={handleSearch}
        disableBtn={disableBtn}
      />
      <CustomTable
        tableColumns={supermasterTableColumns}
        tableData={formattedMasterData}
      />
    </Layout>
  );
};

export default Masters;
