import React, { useEffect, useRef, useState } from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import LoginLogo from "../../assets/loginLogo.png";
import BalanceUpline from "./BalanceUpline";
import SelectOption from "./SelectOption";
import {
  clientListOptions,
  marketOptions,
  masterListOptions,
  projectListOptions,
  superMasterListOptions,
} from "./constants";
import { Link, useNavigate } from "react-router-dom";
import { useProfile } from "../../hooks/profile-hook";

const Header = ({ setShowSidebar }) => {
  const navigate = useNavigate();
  const [currentDropdown, setCurrentDropdown] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const name = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const { getProfileData, profileData } = useProfile();
  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };
  const isMounted = useRef(true);
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) getProfileData();

    return () => {
      isMounted.current = false;
    };
  }, []);
  return (
    <div
      className={`h-[65px] w-[100%] bg-black flex items-center justify-between`}
    >
      <div className="flex w-[20%] justify-around">
        {windowWidth < 994 && (
          <div
            className={`pt-[10px] cursor-pointer`}
            onClick={() => setShowSidebar(true)}
          >
            <TfiMenuAlt color="white" fontSize={"30px"} />
          </div>
        )}

        <Link className={`pt-[10px]`} to={"/"}>
          <img src={LoginLogo} alt={LoginLogo} width="115px" />
        </Link>
      </div>
      {windowWidth > 994 && (
        <div className="w-[50%] text-white flex items-center justify-around">
          <BalanceUpline profileData={profileData} />
          <SelectOption
            selectLabel="Market"
            optionArr={marketOptions}
            setCurrentDropdown={setCurrentDropdown}
            currentDropdown={currentDropdown}
          />
          <SelectOption
            selectLabel={
              role === "9"
                ? "Supermaster List"
                : role === "3"
                ? "Master List"
                : "Client List"
            }
            optionArr={
              role === "9"
                ? superMasterListOptions
                : role === "3"
                ? masterListOptions
                : clientListOptions
            }
            optionLabel = {role === "9"
              ? "SM"
              : role === "3"
              ? "M"
              : "C"}
            setCurrentDropdown={setCurrentDropdown}
            currentDropdown={currentDropdown}
          />
          {!(role === "2") && (
            <div
              className="cursor-pointer"
              onClick={() => navigate("/create-user")}
            >
              Create User
            </div>
          )}

          <div
            className="cursor-pointer"
            onClick={() => navigate("/create-client")}
          >
            Create Client
          </div>
          <SelectOption
            selectLabel={name}
            optionArr={projectListOptions}
            setCurrentDropdown={setCurrentDropdown}
            currentDropdown={currentDropdown}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
