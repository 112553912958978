export const error = {
  usernameRequired: "Username is required",
  passwordRequired: "Password is required",
  nameRequired: "Name is required",
  settlementAmountRequired: "Settled Amount Require",
  userRequired: "User is Required",
  parentRequired: "Parent is Required",
  shareRequired: "Share is Required",
  roleRequired: "Role is Required",
  DepositChips: "Deposit Chips is Required",
};
