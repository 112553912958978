import React, { useRef, useState } from "react";
import Header from "../containers/Header/Header";
import Sidebar from "../containers/Sidebar/Sidebar";
import PageHeading from "../components/PageHeading";
import Marquee from "../containers/Marquee/Marquee";

const Layout = ({ heading, marqueeText, children }) => {
  const ref = useRef(null);
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div>
      <Header setShowSidebar={setShowSidebar} />
      <PageHeading title={heading} />
      <div className="bg-[#f3f3f3] h-full">
        <Marquee marqueeText={marqueeText} />
        {children}
      </div>
      <Sidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        ref={ref}
      />
    </div>
  );
};

export default Layout;
