import { Box, TextField } from "@mui/material";
import React from "react";

const AnimatedInputField = ({
  label,
  type = "text",
  register = false,
  validation,
  errorCheck = false,
  field,
}) => {
  return (
    <Box
      component="form"
      sx={{
        width: "100%",
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="standard-basic"
        label={
          label === "RePassword"
            ? "Re-Password"
            : label === "settlementAmount"
            ? "Settlement Amount"
            : label === "CreatedBy"
            ? "Created By"
            : label === "MobileNumber"
            ? "Mobile Number"
            : label === "DepositChips"
            ? "Deposit Chips"
            : label
        }
        variant="standard"
        fullWidth
        type={type}
        {...(register && register(label, validation))}
        sx={{
          "& .MuiInput-root": {
            color: "#000",
            fontFamily: "Arial",
            fontWeight: "bold",
            // Border on focus
            "&:after": {
              borderColor: errorCheck ? "red" : "#CC9647",
              borderWidth: "1px",
            },
          },
          // Label
          "& .MuiInputLabel-standard": {
            color: errorCheck ? "red" : "#2e2e2e",
            "&.Mui-focused": {
              color: errorCheck ? "red" : "#CC9647",
            },
          },
        }}
        {...field}
      />
    </Box>
  );
};

export default AnimatedInputField;
