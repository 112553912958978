import React, { useState } from "react";
import { PROFIT_LOSS_SUCCESS } from "../redux/auth/actionType";
import { responseCode } from "../constants/responseCode";
import { endPoints } from "../services/EndPoints";
import { constants } from "../constants/constants";
import { logoutErrors } from "../constants/ErrorMessage";
import { toast } from "react-toastify";
import { _get } from "../services/HttpMethods";
import { useDispatch, useSelector } from "react-redux";
import {
  fourDaysBackDate,
  removeAuthData,
  timeConversionForReactDatePicker,
} from "../utils/common";
import useNavigateHook from "./useNavigateHook";

const useProfitLoss = (location) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const profitLoss = useSelector((state) => state.auth.ProfitLoss);
  const [profitLossFilters, setProfitLossFilters] = useState({
    sports: "",
    startDate: fourDaysBackDate(),
    endDate: new Date(),
  });
  const [disableBtn, setDisableBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleFilterChanges = (prop, value) => {
    setProfitLossFilters((prev) => {
      return { ...prev, [prop]: value };
    });
    setDisableBtn(false);
  };
  const { handleNavigate } = useNavigateHook();

  const logoutFunc = () => {
    handleNavigate("/login");
  };

  const getProfitLoss = async (data) => {
    let formatObj = {
      eventType: data.sports,
      startDate: timeConversionForReactDatePicker(data.startDate),
      endDate: timeConversionForReactDatePicker(data.endDate),
    };
    try {
      // GET request to fetch profit loss data
      const response = await _get(
        endPoints.profitLoss,
        null,
        token,
        formatObj,
        null,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== responseCode._200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: PROFIT_LOSS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
  };

  const getAccountStatementForClient = async (clientData, filters) => {
    setLoading(true);
    let formatObj = {
      eventType: filters.sports,
      type: "Profit/Loss",
      startDate: timeConversionForReactDatePicker(filters.startDate),
      endDate: timeConversionForReactDatePicker(filters.endDate),
    };
    try {
      const response = await _get(
        endPoints.clients + "/" + clientData?.clientId + endPoints.accStatement,
        null,
        token,
        formatObj,
        null,
        logoutFunc
      );
      const { status, data, msg } = response;
      if (status !== 200) {
        throw new Error(msg);
      }
      dispatch({
        type: PROFIT_LOSS_SUCCESS,
        payload: data,
      });
      toast.success(msg);
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  };

  const handleSearch = () => {
    setDisableBtn(true);
    if (location?.state?.arr) {
      getAccountStatementForClient(location?.state?.arr, profitLossFilters);
    } else getProfitLoss(profitLossFilters);
  };

  return {
    getProfitLoss,
    profitLoss,
    handleSearch,
    handleFilterChanges,
    profitLossFilters,
    disableBtn,
    getAccountStatementForClient,
    loading,
  };
};

export default useProfitLoss;
