import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { sortByCreatedDate } from "../utils/common";

function useDynamoDb(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

  // Configure AWS
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: new AWS.Credentials(
      process.env.REACT_APP_AWS_ACCESS_KEY,
      process.env.REACT_APP_AWS_SECRET_KEY
    ),
  });

  const fetchData = async (paginationKey = null) => {
    setLoading(true);
    const dynamodb = new AWS.DynamoDB();
    const params = {
      TableName: props.tableName,
      ExclusiveStartKey: paginationKey,
      ...(!props.removeFilter && {
        FilterExpression:
          "deleted = :deletedValue AND declared = :declaredValue",
        ExpressionAttributeValues: {
          ":deletedValue": { N: "0" },
          ":declaredValue": { N: "0" },
        },
      }),
    };
    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error retrieving data from DynamoDB:", err);
        setLoading(false);
      } else {
        console.log("Data retrieved successfully:", result.Items);
        setData([...data, ...result.Items]);
        setLastEvaluatedKey(result.LastEvaluatedKey);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    let flag = true;

    // Fetch data initially
    if (flag) fetchData();

    return () => {
      console.log("Cleaning up initial fetch resources");
      flag = false;
    };
  }, [props.tableName]);

  // useEffect(() => {
  //   let listener
  //   const dynamoDBStreamClient = new AWS.DynamoDBStreams()
  //   const streamParams = {
  //     StreamArn:
  //       'arn:aws:dynamodb:us-east-1:518268429588:table/rdy_matches/stream/2024-05-21T04:02:59.019',
  //   }

  //   dynamoDBStreamClient.describeStream(streamParams, (err, data) => {
  //     if (err) {
  //       console.error('Error describing DynamoDB stream:', err)
  //     } else {
  //       console.log('DynamoDB stream:', data)
  //       fetchData()
  //     }
  //   })

  //   return () => {
  //     // Clean up subscriptions
  //     if (listener) {
  //       listener.unsubscribe((err) => {
  //         if (err) {
  //           console.error('Error unsubscribing from DynamoDB stream:', err)
  //         } else {
  //           console.log('Unsubscribed from DynamoDB stream')
  //         }
  //       })
  //     }
  //   }
  // }, [])

  const sortedByCreatedDate = sortByCreatedDate(data);

  const loadMore = () => {
    if (lastEvaluatedKey) {
      fetchData(lastEvaluatedKey);
    }
  };

  return {
    data: sortedByCreatedDate,
    loadMore,
    loading,
  };
}

export default useDynamoDb;
