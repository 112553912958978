export const sortingArr = [
  { value: "Select", label: "Select" },
  { value: "PL ASC", label: "PL ASC" },
  { value: "PL DESC", label: "PL DESC" },
  { value: "Expose ASC", label: "Expose ASC" },
  { value: "Expose DESC", label: "Expose DESC" },
];

export const clientsTableColumns = [
  {
    id: "username",
    numeric: false,
    disablePadding: true,
    label: "Username",
    width: "20%",
  },
  {
    id: "balance",
    numeric: false,
    disablePadding: true,
    label: "Balance",
    width: "1%",
  },
  {
    id: "pl",
    numeric: true,
    disablePadding: true,
    label: "P/L",
    width: "1%",
  },
  {
    id: "exp",
    numeric: false,
    disablePadding: true,
    label: "Exp",
    width: "1%",
  },
  {
    id: "clientShare",
    numeric: false,
    disablePadding: true,
    label: "Client Share",
    width: "1%",
  },
  {
    id: "upLine",
    numeric: false,
    disablePadding: true,
    label: "Up-Line",
    width: "15%",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    width: "1%",
  },
  {
    id: "bet",
    numeric: false,
    disablePadding: true,
    label: "Bet",
    width: "1%",
  },
  {
    id: "options",
    numeric: false,
    disablePadding: true,
    label: "Options",
    width: "40%",
  },
];