import { useEffect, useRef, useState } from "react";
import { endPoints } from "../services/EndPoints";
import { _get } from "../services/HttpMethods";
import { useDispatch, useSelector } from "react-redux";
import useNavigateHook from "./useNavigateHook";
import { GET_CHIP_SMRY } from "../redux/chipsmry/actionType";
import { toast } from "react-toastify";

export const useChipSmry = (chipSmryObject) => {
  const token = useSelector((state) => state.auth.token);
  const { handleNavigate } = useNavigateHook();
  const dispatch = useDispatch();
  const { chipSmry } = useSelector((state) => state.chipSmry);
  const [formattedChipSmry, setFormattedChipSmry] = useState([]);
  const [loading, setLoading] = useState(false);
  const getFormattedChipSmry = (chipSmryObject) => {
    console.log(chipSmryObject, "chipSmryObject");
    return [
      {
        title: "User",
        value: chipSmry.username,
      },

      {
        title: "Current Balance",
        value: chipSmry.currentBalance,
      },

      {
        title: "Down-Line Credit Remaining",
        value: chipSmry.downLineCredit,
      },
      {
        title:
          chipSmryObject.role === "3"
            ? "Master Total Wallet"
            : "Client Total Wallet",
        value:
          chipSmryObject.role === "3"
            ? chipSmry.MastersTotalWallet
            : chipSmry.clientTotalWallet,
      },
      {
        title: "Up-line PL",
        value: chipSmry.upLinePL,
      },
      {
        title: chipSmryObject.role === "3" ? "Total Masters" : "Total Clients",
        value:
          chipSmryObject.role === "3"
            ? chipSmry.totalMasters
            : chipSmry.totalClients,
      },
    ];
  };

  useEffect(() => {
    setFormattedChipSmry(getFormattedChipSmry(chipSmryObject));
  }, [chipSmry]);

  const logoutFunc = () => {
    handleNavigate("/login");
  };

  const getChipSmryData = async () => {
    setLoading(true);
    try {
      const response = await _get(
        endPoints.chipSmry,
        null,
        token,
        null,
        null,
        logoutFunc
      );
      const { status, data, msg } = response;
      if (status !== 200) {
        throw new Error(msg);
      }
      dispatch({
        type: GET_CHIP_SMRY,
        payload: data,
      });
      toast.success(msg);
    } catch (err) {
      toast.error(err.message);
      dispatch({
        type: GET_CHIP_SMRY,
        payload: {},
      });
    }
    setLoading(false);
  };

  const getChipSmryDataForClient = async (url) => {
    setLoading(true);
    try {
      const response = await _get(url, null, token, null, null, logoutFunc);
      const { status, data, msg } = response;
      if (status !== 200) {
        throw new Error(msg);
      }
      dispatch({
        type: GET_CHIP_SMRY,
        payload: data,
      });
      toast.success(msg);
    } catch (err) {
      toast.error(err.message);
      dispatch({
        type: GET_CHIP_SMRY,
        payload: {},
      });
    }
    setLoading(false);
  };

  return {
    formattedChipSmry,
    getChipSmryData,
    getChipSmryDataForClient,
    loading,
  };
};
