export const componentNames = {
  Login: "Login",
  Home: "Home",
  PageNotFound: "Page Not Found",
  CreateClient: "Create Client",
  Clients: "Clients",
  ProfitLoss: "Profile Loss",
  ChangePassword: "Change Password",
  AccountStatement: "Account Statement",
  weeklyReport: "Weekly Report",
  ChipSummary: "ChipSummary",
  SportReport: "Sport Report",
  SettlementReport: "Settlement Report",
  Export: "Export",
  BankWithdraw: "Bank Withdraw",
  BankDeposit: "Bank Deposit",
  CreateUser: "Create User",
  BetHistory: "Bet History",
  EditProfile: "profile-edit",
  ChipStatement: "Chip Statement",
  Supermaster: "Supermaster",
  Master : "Master",
  Event: "Event"
};
