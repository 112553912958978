import React, { useEffect, useRef } from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import SearchClients from "./SearchClients";
import CustomTable from "../../containers/CustomTable/CustomTable";
import { clientsTableColumns } from "./constants";
import { useClient } from "../../hooks/client-hook";
import { useLocation } from "react-router-dom";

const Clients = () => {
  const name = localStorage.getItem("userName");
  const location = useLocation();
  const {
    getClients,
    formattedClientsData,
    filters,
    handleChangeFilter,
    handleSearch,
    disableBtn,
  } = useClient();
  const isMounted = useRef(true);
  useEffect(() => {
    if (isMounted.current) {
      getClients(null, location.state);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Layout heading={name} marqueeText={`${commonMarqueeContent}`}>
      <SearchClients
        filters={filters}
        handleChangeFilter={handleChangeFilter}
        handleSearch={handleSearch}
        disableBtn={disableBtn}
      />
      <CustomTable
        tableColumns={clientsTableColumns}
        tableData={formattedClientsData}
      />
    </Layout>
  );
};

export default Clients;
