import React from "react";

const SearchButton = ({ handleSearch, btnTitle = "SEARCH", disableBtn }) => {
  return (
    <div
      onClick={disableBtn ? () => {} : handleSearch}
      className="bg-[#CC9647] py-[10px] px-[20px] text-white rounded-sm cursor-pointer"
    >
      {btnTitle}
    </div>
  );
};

export default SearchButton;
