export const clientsTableColumns = [
  {
    id: "NO",
    numeric: false,
    disablePadding: true,
    label: "NO",
    width: "3%",
  },
  {
    id: "EVENT",
    numeric: false,
    disablePadding: true,
    label: "EVENT",
    width: "20%",
  },
  {
    id: "COMM IN",
    numeric: false,
    disablePadding: true,
    label: "COMM IN",
    width: "20%",
  },
  {
    id: "COMM OUT",
    numeric: false,
    disablePadding: true,
    label: "COMM OUT",
    width: "20%",
  },
  {
    id: "AMOUNT",
    numeric: false,
    disablePadding: true,
    label: "AMOUNT",
    width: "20%",
  },
  {
    id: "TOTAL",
    numeric: false,
    disablePadding: true,
    label: "TOTAL",
    width: "9%",
  },
  {
    id: "INFO",
    numeric: false,
    disablePadding: true,
    label: "INFO",
    width: "9%",
  },
];

export const config = {
  serialNumber: {
    field: "serialNumber",
    type: "value",
    style: { fontWeight: "bold" },
  },
  debitCredit: {
    field: "debitCredit",
    type: "value",
    value: (value) => value?.debitCredit,
  },
  comm_in: {
    field: "comm_in",
    type: "value",
    value: (value) => value?.comm_in ?? "-",
  },
  comm_out: {
    field: "comm_out",
    type: "value",
    value: (value) => value?.comm_out ?? "-",
  },
  amount: {
    type: "value",
    value: (value) => value?.amount,
  },
  total: {
    type: "value",
    value: (value) => value?.client?.current,
    style: { fontWeight: "bold" },
  },
  desc: {
    field: "desc",
    type: "value",
    value: (value) => value?.desc ?? "-",
  },
};
