export const supermasterTableColumns = [
    {
      id: "username",
      numeric: false,
      disablePadding: true,
      label: "Username",
    //   width: "20%",
    },
    {
      id: "balance",
      numeric: false,
      disablePadding: true,
      label: "Balance",
    //   width: "1%",
    },
    {
      id: "pl",
      numeric: true,
      disablePadding: true,
      label: "P/L",
    //   width: "1%",
    },
    {
      id: "share",
      numeric: false,
      disablePadding: true,
      label: "Share",
    //   width: "1%",
    },
    {
      id: "admin",
      numeric: false,
      disablePadding: true,
      label: "admin",
    //   width: "1%",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    //   width: "15%",
    },
    {
      id: "options",
      numeric: false,
      disablePadding: true,
      label: "Options",
      width: "40%",
    },
  ];