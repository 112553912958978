import React from "react";
import ScoreBG from "../../assets/score_bg.webp";
const EventScoreCard = ({ scoreData }) => {
  return (
    <div
      className="w-[100%] font-semibold text-white"
      style={{
        backgroundImage: `url(${ScoreBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%", // or any specific width
        height: "100%", // or any specific height
      }}
    >
      <div className="flex justify-around text-center">
        <div className="w-[25%]">
          {/* first */}
          <div>{scoreData?.spnnation1}</div>
          <div>
            <span>{scoreData?.score1}</span>
          </div>
          <div>
            <span>CRR:{scoreData?.spnrunrate1} |</span>
            <span> RRR :{scoreData?.spnreqrate1}</span>
          </div>
        </div>
        <div className="w-[46%]">
          <div className="animate-slide-in-out">
            {scoreData?.spnballrunningstatus}
          </div>
          <div>{scoreData?.spnmessage}</div>
          <div>
            {scoreData?.balls?.map((item, indx) => {
              return <span key={item + "ball" + indx} className="mx-1">{item}</span>;
            })}
          </div>
        </div>
        <div className="w-[25%]">
          {/* last */}
          <div>{scoreData?.spnnation2}</div>
          <div>
            <span>{scoreData?.score2}</span>
          </div>
          <div>
            <span>CRR: {scoreData?.spnrunrate2} |</span>
            <span> RRR : {scoreData?.spnreqrate2}</span>
          </div>
        </div>
      </div>
      <div>{/* lower content */}</div>
    </div>
  );
};

export default EventScoreCard;
