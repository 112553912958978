import ProfitLoss from "../../assets/sidebar/profitloss.svg";
import Bets from "../../assets/sidebar/bets.svg";
import CreateClient from "../../assets/sidebar/createClient.svg";
import Logout from "../../assets/sidebar/logout.svg";
import Multi from "../../assets/sidebar/multi.svg";
import Settlement from "../../assets/sidebar/settlement.svg";
import Users from "../../assets/sidebar/users.svg";

export const sidebarArr = [
    {
      img: ProfitLoss,
      title: "Market Analysis",
    },
    {
      img: Multi,
      title: "Multi Market",
    },
    {
      img: Users,
      title: "Users List",
    },
    {
      img: CreateClient,
      title: "Create Client",
      nav:"/create-client"
    },
    {
      img: Bets,
      title: "Bets",
    },
    {
      img: Settlement,
      title: "Settlement",
    },
    {
      img: ProfitLoss,
      title: "Export",
    },
    {
      img: Logout,
      title: "LOGOUT",
      color: "red",
    },
  ];
