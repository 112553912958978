import { componentNames } from "../constants/componentName";
import AccountStatement from "../pages/AccountStatement/AccountStatement";
import BetHistory from "../pages/BetHistory/BetHistory";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import ChipStatement from "../pages/ChipStatement/ChipStatement";
import ChipSummary from "../pages/ChipSummary/ChipSummary";
import Clients from "../pages/Clients/Clients";
import CreateClient from "../pages/CreateClient/CreateClient";
import CreateUser from "../pages/CreateUser/CreateUser";
import Deposit from "../pages/Deposit/Deposit";
import EditProfile from "../pages/EditProfile/EditProfile";
import Event from "../pages/Event/Event";
import Export from "../pages/Export/Export";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Masters from "../pages/Masters/Masters";
import NotFound from "../pages/NotFound/NotFound";
import ProfileLoss from "../pages/ProfitLoss/ProfileLoss";
import SettlementReport from "../pages/Settlement/SettlementReport";
import SportReport from "../pages/SportReport/SportReport";
import Supermaster from "../pages/Supermaster/Supermaster";
import WeeklyReport from "../pages/WeeklyReport/WeeklyReport";
import Withdraw from "../pages/Withdraw/Withdraw";
import { URLs } from "./URL";

export const routes = [
  {
    id: 0,
    title: componentNames.Login,
    path: URLs.login,
    comp: <Login />,
    isProtected: false,
  },
  {
    id: 1,
    title: componentNames.Home,
    path: URLs.home,
    comp: <Home />,
    isProtected: true,
  },
  {
    id: 2,
    title: componentNames.PageNotFound,
    path: URLs.notFound,
    comp: <NotFound />,
    isProtected: false,
  },
  {
    id: 3,
    title: componentNames.CreateClient,
    path: URLs.createClient,
    comp: <CreateClient />,
    isProtected: true,
  },
  {
    id: 4,
    title: componentNames.Clients,
    path: URLs.clients,
    comp: <Clients />,
    isProtected: true,
  },
  {
    id: 5,
    title: componentNames.ProfitLoss,
    path: URLs.profitLoss,
    comp: <ProfileLoss />,
    isProtected: true,
  },
  {
    id: 6,
    title: componentNames.ChangePassword,
    path: URLs.profile,
    comp: <ChangePassword />,
    isProtected: true,
  },
  {
    id: 7,
    title: componentNames.AccountStatement,
    path: URLs.accountStatement,
    comp: <AccountStatement />,
    isProtected: true,
  },

  {
    id: 8,
    title: componentNames.weeklyReport,
    path: URLs.weeklyReport,
    comp: <WeeklyReport />,
    isProtected: true,
  },
  {
    id: 9,
    title: componentNames.ChipSummary,
    path: URLs.chipSummary,
    comp: <ChipSummary />,
    isProtected: true,
  },
  {
    id: 10,
    title: componentNames.SportReport,
    path: URLs.sportReport,
    comp: <SportReport />,
    isProtected: true,
  },
  {
    id: 11,
    title: componentNames.SettlementReport,
    path: URLs.settlementReport,
    comp: <SettlementReport />,
    isProtected: true,
  },
  {
    id: 12,
    title: componentNames.Export,
    path: URLs.export,
    comp: <Export />,
    isProtected: true,
  },
  {
    id: 13,
    title: componentNames.BankWithdraw,
    path: URLs.withdraw,
    comp: <Withdraw />,
    isProtected: true,
  },
  {
    id: 14,
    title: componentNames.CreateUser,
    path: URLs.createUser,
    comp: <CreateUser />,
    isProtected: true,
  },
  {
    id: 15,
    title: componentNames.BankDeposit,
    path: URLs.deposit,
    comp: <Deposit />,
    isProtected: true,
  },
  {
    id: 16,
    title: componentNames.BetHistory,
    path: URLs.betHistory,
    comp: <BetHistory />,
    isProtected: true,
  },
  {
    id: 17,
    title: componentNames.EditProfile,
    path: URLs.profileEdit,
    comp: <EditProfile />,
    isProtected: true,
  },
  {
    id: 18,
    title: componentNames.ChipStatement,
    path: URLs.chipStatement,
    comp: <ChipStatement />,
    isProtected: true,
  },
  {
    id: 19,
    title: componentNames.Supermaster,
    path: URLs.supermasters,
    comp: <Supermaster />,
    isProtected: true,
  },
  {
    id: 20,
    title: componentNames.Master,
    path: URLs.masters,
    comp: <Masters />,
    isProtected: true,
  },
  {
    id: 21,
    title: componentNames.Event,
    path: URLs.event,
    comp: <Event />,
    isProtected: true,
  },
];
