import { getSportsDetails } from "../../utils/common";
import { ACTIONTYPES } from "./actionTypes";

const { sportsData } = getSportsDetails();

const initialState = { sportsData: sportsData };

const sportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONTYPES.SPORTS_DETAILS_SUCCESS:
      return {
        ...state,
        sportsData: action.payload,
      };
    case ACTIONTYPES.SPORTS_DETAILS_FAIL:
      return {
        ...state,
        sportsData: [],
      };
    default:
      return state;
  }
};

export default sportsReducer;
