import React, { useState } from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import { Card, CardContent, Typography, Button, Grid2 } from "@mui/material";
import { options } from "./constant";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import DownloadIcon from "@mui/icons-material/Download";
import useExport from "../../hooks/export-hook";

const Export = () => {
  const {
    statementType,
    startDate,
    endDate,
    handleStatementTypeChange,
    handleExport,
    setStartDate,
    setEndDate,
  } = useExport();

  return (
    <Layout heading="Export Options" marqueeText={commonMarqueeContent}>
      <Card
        sx={{
          width: 600,
          padding: 2,
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px auto",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            margin: "5px 0",
            fontSize: "15px",
            textTransform: "uppercase",
            background: "#eee",
            padding: "10px",
            fontWeight: 600,
            border: "1px solid #CC9647",
            width: "100%",
          }}
        >
          ProjectzM's Export Options
        </Typography>
        <Typography sx={{ color: "#F44336", width: "100%" }}>
          * All time will be in IST(UTC +05:30)
        </Typography>
        <Typography sx={{ color: "#F44336", width: "100%" }}>
          * Max 15 Days Export Allowed
        </Typography>
        <CardContent sx={{ width: "100%", padding: 0, marginTop: "10px" }}>
          <Grid2 container alignItems={"center"} sx={{ margin: "10px 0px" }}>
            <Grid2 flex={0.3}>
              <Typography
                variant="label"
                component="label"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: "18px" }}
              >
                Select Type
              </Typography>
            </Grid2>
            <Grid2 flex={0.7}>
              <SearchInputs
                variant={"standard"}
                field={"selectField"}
                label={"Select Type"}
                optionArr={options}
                value={statementType}
                onChange={handleStatementTypeChange}
              />
            </Grid2>
          </Grid2>
          <Grid2 container alignItems={"center"} sx={{ margin: "10px 0px" }}>
            <Grid2 flex={0.3} display={"flex"}>
              <Typography
                variant="label"
                component="label"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: "18px" }}
              >
                Start Date
              </Typography>
            </Grid2>
            <Grid2 flex={0.3}>
              <SearchInputs
                field="dateField"
                value={startDate}
                onChange={(newDate) => setStartDate(newDate)}
                showIcon={false}
              />
            </Grid2>
          </Grid2>
          <Grid2 container alignItems={"center"} sx={{ margin: "10px 0px" }}>
            <Grid2 flex={0.3} display={"flex"}>
              <Typography
                variant="label"
                component="label"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: "18px" }}
              >
                End Date
              </Typography>
            </Grid2>
            <Grid2 flex={0.7}>
              <SearchInputs
                field="dateField"
                value={endDate}
                onChange={(newDate) => setEndDate(newDate)}
                showIcon={false}
              />
            </Grid2>
          </Grid2>
        </CardContent>
        <Button
          variant="contained"
          color="primary"
          sx={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            bgcolor: "#008000",
          }}
          startIcon={<DownloadIcon />}
          onClick={() => handleExport({ statementType, startDate, endDate })}
        >
          Excel
        </Button>
      </Card>
    </Layout>
  );
};

export default Export;
