import { Route, BrowserRouter  , Routes } from "react-router-dom";
import "./App.css";
import { routes } from "./routes/Routes";
import PrivateRoute from "./routes/PrivateRoute";
import { useSelector } from "react-redux";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={
              route.isProtected ? (
                <PrivateRoute
                  isAuthenticated={route.isProtected && isAuthenticated}
                >
                  {route.comp}
                </PrivateRoute>
              ) : (
                route.comp
              )
            }
          />
        ))}
      </Routes>
    </BrowserRouter  >
  );
}

export default App;
