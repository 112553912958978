import { GET_CHIP_SMRY } from "./actionType";

const initialState = {
  chipSmry: {},
};

const getChipSmryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHIP_SMRY:
      return {
        ...state,
        chipSmry: action.payload,
      };
    default:
      return state;
  }
};

export default getChipSmryReducer;
