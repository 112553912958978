import React, { useEffect, useRef } from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import SearchForm from "./SearchForm";
import CustomTable from "../../containers/CustomTable/CustomTable";
import { accountStatementTableColumns } from "./constants";
import { useAccountStatement } from "../../hooks/account-statement-hook";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import { endPoints } from "../../services/EndPoints";
const AccountStatement = () => {
  const isMounted = useRef(true);
  const location = useLocation();
  const {
    accStatFilters,
    handleFilterChanges,
    handleSearch,
    formattedAccountStatement,
    disableBtn,
    getAccountStatementForClient,
    getAccountStatement,
    loading,
  } = useAccountStatement();

  useEffect(() => {
    if (isMounted.current) {
      if (location?.state?.arr) {
        getAccountStatementForClient(
          location.state.role === "supermaster"
            ? endPoints.supermaster.slice(1) +
                "/" +
                location?.state?.arr?.id +
                endPoints.accStatement
            : location.state.role === "master"
            ? endPoints.master.slice(1) +
              "/" +
              location?.state?.arr?.id +
              endPoints.accStatement
            : endPoints.clients +
              "/" +
              location?.state?.arr?.clientId +
              endPoints.accStatement
        );
      } else {
        getAccountStatement();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const name = localStorage.getItem("userName");
  return (
    <Layout
      heading={`ACCOUNT STATEMENT[${name}]`}
      marqueeText={`${commonMarqueeContent}`}
    >
      <SearchForm
        accStatFilters={accStatFilters}
        handleFilterChanges={handleFilterChanges}
        handleSearch={handleSearch}
        disableBtn={disableBtn}
      />{" "}
      {loading ? (
        <Loader />
      ) : (
        <CustomTable
          tableColumns={accountStatementTableColumns}
          tableData={formattedAccountStatement}
        />
      )}
    </Layout>
  );
};

export default AccountStatement;
