import { AiOutlineCreditCard } from "react-icons/ai";
import { IoPersonSharp } from "react-icons/io5";
import { MdFace6, MdOutlineGroup, MdOutlineShowChart } from "react-icons/md";
import { PiCurrencyDollarBold } from "react-icons/pi";

export const getFormattedProfileData = (client) => [
  {
    icon: <MdFace6 color="green" fontSize="24px" />,
    title: "Name",
    value: client.name,
  },
  {
    icon: <IoPersonSharp color="green" fontSize="24px" />,
    title: "Username",
    value: client.username,
  },
  {
    icon: <AiOutlineCreditCard color="green" fontSize="24px" />,
    title: "Free Chip",
    value: client.downLineCredit,
  },
  {
    icon: <PiCurrencyDollarBold color="green" fontSize="24px" />,
    title: "P/L",
    value: client.profitLoss,
  },
  {
    icon: <MdOutlineShowChart color="green" fontSize="24px" />,
    title: "Expose",
    value: client.upLinePL,
  },

  //below code need adjustment when we will have proper data
  ...[
    "Cricket",
    "Football",
    "Tennis",
    "Horse",
    "Greyhound",
    "Politics",
    "Casino",
    "iCasino",
  ].map((sport) => ({
    icon: <MdOutlineGroup color="green" fontSize="24px" />,
    title: `${sport} Client Share`,
    value: client.totalClients,
  })),
];
