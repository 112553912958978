import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseCode } from "../constants/responseCode";
import { GET_EXPORT_DATA } from "../redux/export/actionType";
import { endPoints } from "../services/EndPoints";
import { _get, _post } from "../services/HttpMethods";
import { constants } from "../constants/constants";
import { logoutErrors } from "../constants/ErrorMessage";
import { toast } from "react-toastify";
import { removeAuthData } from "../utils/common";
import useNavigateHook from "./useNavigateHook";
import axios from "axios";

const useExport = () => {
  const [statementType, setStatementType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const { handleNavigate } = useNavigateHook();

  const handleStatementTypeChange = (event) => {
    setStatementType(event.target.value);
  };

  const handleExport = (params) => {
    if (!startDate || !startDate || !statementType) {
      return toast.error("Please fill the required fields");
    }

    const _startDate = new Date(params.startDate);
    const _endDate = new Date(params.endDate);

    const startDateMilliseconds = _startDate.getTime();
    const endDateMilliseconds = _endDate.getTime();
    switch (params.statementType) {
      case "account-statement":
        return getExportAccountStatementData({
          startDate: startDateMilliseconds,
          endDate: endDateMilliseconds,
        });
      case "profit-loss":
        return getExportProfitLossStatementData({
          startDate: startDateMilliseconds,
          endDate: endDateMilliseconds,
        });
      case "free-chip-statement":
        return getExportFreeChipStatementData({
          startDate: startDateMilliseconds,
          endDate: endDateMilliseconds,
        });
      case "settlement-statement":
        return getExportSettlementStatement({
          startDate: startDateMilliseconds,
          endDate: endDateMilliseconds,
        });
      default:
        return null;
    }
  };

  const getExportAccountStatementData = async (data) => {
    axios
      .get(
        `${process.env.REACT_APP_URL_DEV}/${endPoints.exportAccountStatement}`,
        {
          headers: { authorizationToken: `Bearer ${token}` },
          params: data,
          responseType: "blob",
        }
      )
      .then((response) => {
        const statusCode = response.status;
        if (statusCode !== responseCode._200) {
          throw new Error(response.msg);
        }
        const url = URL.createObjectURL(response.data);

        const a = document.createElement("a");
        a.href = url;
        a.download = `accountstatement-${data.endDate}.xlsx`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        if (error.message === constants.Unauthorized) {
          removeAuthData();
          toast.info(logoutErrors.sessionExpire);
        } else {
          toast.error(error.message);
        }
      });
  };

  const getExportProfitLossStatementData = async (data) => {
    axios
      .get(
        `${process.env.REACT_APP_URL_DEV}/${endPoints.exportProfitLossStatement}`,
        {
          headers: { authorizationToken: `Bearer ${token}` },
          params: data,
          responseType: "blob",
        }
      )
      .then((response) => {
        const statusCode = response.status;
        if (statusCode !== responseCode._200) {
          throw new Error(response.msg);
        }
        const url = URL.createObjectURL(response.data);

        const a = document.createElement("a");
        a.href = url;
        a.download = `profit-loss-${data.endDate}.xlsx`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        if (error.message === constants.Unauthorized) {
          removeAuthData();
          toast.info(logoutErrors.sessionExpire);
        } else {
          toast.error(error.message);
        }
      });
  };

  const getExportFreeChipStatementData = async (data) => {
    axios
      .get(
        `${process.env.REACT_APP_URL_DEV}/${endPoints.exportFreeChipStatement}`,
        {
          headers: { authorizationToken: `Bearer ${token}` },
          params: data,
          responseType: "blob",
        }
      )
      .then((response) => {
        const statusCode = response.status;
        if (statusCode !== responseCode._200) {
          throw new Error(response.msg);
        }
        const url = URL.createObjectURL(response.data);

        const a = document.createElement("a");
        a.href = url;
        a.download = `free-chip-${data.endDate}.xlsx`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        if (error.message === constants.Unauthorized) {
          removeAuthData();
          toast.info(logoutErrors.sessionExpire);
        } else {
          toast.error(error.message);
        }
      });
  };

  const getExportSettlementStatement = async (data) => {
    axios
      .get(
        `${process.env.REACT_APP_URL_DEV}/${endPoints.exportSettlementStatement}`,
        {
          headers: { authorizationToken: `Bearer ${token}` },
          params: data,
          responseType: "blob",
        }
      )
      .then((response) => {
        const statusCode = response.status;
        if (statusCode !== responseCode._200) {
          throw new Error(response.msg);
        }
        const url = URL.createObjectURL(response.data);

        const a = document.createElement("a");
        a.href = url;
        a.download = `settlement-${data.endDate}.xlsx`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        if (error.message === constants.Unauthorized) {
          removeAuthData();
          toast.info(logoutErrors.sessionExpire);
        } else {
          toast.error(error.message);
        }
      });
  };

  return {
    statementType,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    handleStatementTypeChange,
    handleExport,
  };
};

export default useExport;
