import React, { useEffect, useRef } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { URLs } from "../../routes/URL";
import { useLogin } from "../../hooks/login-hook";

const SelectOption = ({
  selectLabel,
  optionArr = [],
  setCurrentDropdown,
  currentDropdown,
  optionLabel = "",
}) => {
  const ref = useRef(null);
  const token = useSelector((state) => state.auth.token);
  const name = localStorage.getItem("userName");
  const navigate = useNavigate();
  const { handleLogoutFunction } = useLogin();
  const handleDropdownClick = (e) => {
    if (currentDropdown === selectLabel) {
      setCurrentDropdown(null);
    } else setCurrentDropdown(selectLabel);
  };
  useEffect(() => {
    // Handler to close the dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setCurrentDropdown(null);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="relative">
      <div className="flex cursor-pointer" onClick={handleDropdownClick}>
        <div>{selectLabel}</div>
        <div className="flex items-center justify-around">
          <IoMdArrowDropdown />
        </div>
      </div>
      {currentDropdown && currentDropdown === selectLabel && (
        <div
          ref={ref}
          className={`absolute text-black top-[44px] right-[0px] w-[170px] border border-gray-300 z-50 bg-white text-xs font-black  ${
            currentDropdown === "ProjectzM" ? "left-[-60px]" : null
          }`}
        >
          {optionArr.map((item, indx) => (
            <div
              className={`flex items-center text-[12px] p-[14px] px-[16px] border-b border-gray-300 cursor-pointer font-lato hover:bg-[#d6d6d6]`}
              key={item.name + indx}
              onClick={() =>
                item.url ? navigate(item?.url, { state: item?.params }) : null
              }
            >
              {item.spanCheck && (
                <div
                  className={`p-2 text-white mr-[5px] font-black`}
                  style={{
                    backgroundColor: item.color,
                  }}
                >
                  {optionLabel}
                </div>
              )}
              <div
                className={`${item.bold ? "font-black" : null}`}
                style={{
                  color: item.color,
                }}
              >
                {item.name}
              </div>
            </div>
          ))}
          {currentDropdown === name && (
            <div
              className="text-[16px] p-[12px] px-[16px] border-b border-gray-300 cursor-pointer font-lato text-white font-black bg-red-600"
              onClick={() =>
                handleLogoutFunction(token, () => navigate(URLs.login))
              }
            >
              Logout
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectOption;
