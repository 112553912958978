import React, { useEffect, useRef } from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import SearchForm from "./SearchForm";
import CustomTable from "../../containers/CustomTable/CustomTable";
import { betHistoryColumns } from "./constants";
import { useLocation } from "react-router-dom";
import { useBet } from "../../hooks/bets-hook";
import Loader from "../../components/Loader";

const BetHistory = () => {
  const location = useLocation();
  const isMounted = useRef(true);
  const {
    getUnsettledBetsForClients,
    betFilters,
    handleFilterChanges,
    getBetsForUser,
    btnDisable,
    bets,
    loading,
  } = useBet();
  const getBetsData = () => {
    if (location?.state?.getBets) {
      getUnsettledBetsForClients(location.state.arr, {
        ...betFilters,
      });
    } else if (location?.state?.arr) {
      getUnsettledBetsForClients(location.state.arr, {
        settled: false,
        ...betFilters,
      });
    } else {
      getBetsForUser(betFilters);
    }
  };

  useEffect(() => {
    if (isMounted.current) getBetsData();
    return () => {
      isMounted.current = false;
    };
  }, []);
  return (
    <Layout heading="Bet History" marqueeText={`${commonMarqueeContent}`}>
      <SearchForm
        betFilters={betFilters}
        handleFilterChanges={handleFilterChanges}
        getBetsData={getBetsData}
        btnDisable={btnDisable}
      />
      {loading ? (
        <Loader />
      ) : (
        <CustomTable tableColumns={betHistoryColumns} tableData={bets} />
      )}
    </Layout>
  );
};

export default BetHistory;
