import React, { useEffect } from "react";
import LoginLogo from "../../assets/loginLogo.png";
import "./style.css";
import { commonCss, constants } from "../../constants/constants";
import { useForm, Controller } from "react-hook-form";
import { error } from "../../constants/errors";
import { useLogin } from "../../hooks/login-hook";
import Loader from "../../components/Loader";
import ErrorField from "../../components/ErrorField";
import { getAuthData } from "../../utils/common";
import useNavigateHook from "../../hooks/useNavigateHook";
import AnimatedInputField from "../../components/AnimatedInputField";

const Login = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const { submitLogin, loading } = useLogin();
  const { handleNavigate } = useNavigateHook();
  const onSubmit = (data) => {
    submitLogin(data);
    reset({
      username: "",
      password: "",
    });
  };

  useEffect(() => {
    let auth = getAuthData();
    if (auth.token) {
      handleNavigate("/");
    }
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <div
        className={`w-screen h-screen ${commonCss.flex_ic_js} bg-login-custom-gradient`}
      >
        <div>
          <div className={`w-96 ${commonCss.flex_ic_js} pb-[5%]`}>
            <img src={LoginLogo} alt={LoginLogo} width="186px" />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`bg-white p-8 rounded shadow-lg w-96 ${commonCss.flex_ic_js} flex-col`}
          >
            <div
              className={`login-heading w-[300px] ${commonCss.flex_ic_js} mb-[15px]`}
            >
              <h2>{constants.SignIn}</h2>
            </div>
            <div className={`w-[300px] ${commonCss.flex_ic_js} flex-col`}>
              <div className="mb-[15px] w-[fill-available]">
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <AnimatedInputField
                      label="username"
                      type="text"
                      register={register}
                      validation={{ required: true }}
                      errorCheck={errors.username}
                      {...field}
                    />
                  )}
                />
                {errors.username && (
                  <ErrorField error={error.usernameRequired} />
                )}
              </div>
              <div className="mb-[15px] w-[fill-available]">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <AnimatedInputField
                      label="password"
                      type="password"
                      register={register}
                      validation={{ required: true }}
                      errorCheck={errors.password}
                      {...field}
                    />
                  )}
                />
                {errors.password && (
                  <ErrorField error={error.passwordRequired} />
                )}
              </div>
            </div>
            <div className={`w-[300px] flex items-start`}>
              <div className={`remember-div ${commonCss.flex_ic_js}`}>
                <input
                  type="checkbox"
                  id="remember-me"
                  className="cursor-pointer"
                />
                <label
                  className={`ml-[5px] text-color_5a5a5a text-base cursor-pointer`}
                  for="remember-me"
                >
                  {constants.RememberMe}?
                </label>
              </div>
            </div>
            <button
              className={`text-white bg-[#003559] w-full capitalize text-sm rounded-sm p-[10px] mt-[10px] cursor-pointer`}
            >
              {constants.Login}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
