import React from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import SearchForm from "./SearchForm";
import { useSportReport } from "../../hooks/sportReport-hook";
import Report from "./Report";
import { useLocation } from "react-router-dom";

const SportReport = () => {
  const name = localStorage.getItem("userName");
  const location = useLocation()
  const {
    sportReportFilters,
    handleFilterChanges,
    handleSearch,
    handleLifetimeSearch,
    disableBtn,
    sportReport,
  } = useSportReport(location);
  return (
    <Layout
      heading={`SPORT REPORT[${name}]`}
      marqueeText={`${commonMarqueeContent}`}
    >
      <SearchForm
        sportReportFilters={sportReportFilters}
        handleFilterChanges={handleFilterChanges}
        handleSearch={handleSearch}
        handleLifetimeSearch={handleLifetimeSearch}
        disableBtn={disableBtn}
      />
      <Report
        sportReport={sportReport}
        sportReportFilters={sportReportFilters}
      />
    </Layout>
  );
};

export default SportReport;
