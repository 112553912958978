import { useDispatch, useSelector } from "react-redux";
import { endPoints } from "../services/EndPoints";
import { _get } from "../services/HttpMethods";
import useNavigateHook from "./useNavigateHook";
import { responseCode } from "../constants/responseCode";
import { constants } from "../constants/constants";
import { removeAuthData } from "../utils/common";
import { toast } from "react-toastify";
import { logoutErrors } from "../constants/ErrorMessage";
import { GET_PROFILE_DATA } from "../redux/profile/actionType";

export const useProfile = () => {
  const token = useSelector((state) => state.auth.token);
  const { handleNavigate } = useNavigateHook();
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  const logoutFunc = () => {
    handleNavigate("/login");
  };

  const getProfileData = async () => {
    try {
      // GET request to fetch client data
      const response = await _get(
        endPoints.profile,
        null,
        token,
        {},
        false,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== responseCode._200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_PROFILE_DATA,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
  };
  return {
    getProfileData,
    profileData
  };
};
