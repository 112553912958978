import { GET_SUPERMASTER_OPTIONS } from "./actionTypes";

const initialState = {
  supermasterOption: [],
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUPERMASTER_OPTIONS:
          return {
            ...state,
            supermasterOption: action.payload,
          };
        default:
          return state;
      }
};

export default userReducer;
