import React from "react";

const BalanceUpline = ({profileData}) => {
  return (
    <div className="bg-white text-black w-[105px] text-center rounded-[2px]">
      <div>
        Bal : <b>{profileData?.current || 0}</b>
      </div>
      <div>
        Upline : <b>{profileData?.profitLoss || 0}</b>
      </div>
    </div>
  );
};

export default BalanceUpline;
