import { useDispatch, useSelector } from "react-redux";
import { responseCode } from "../constants/responseCode";
import { GET_CLIENT_SUCCESS } from "../redux/clients/actionType";
import { endPoints } from "../services/EndPoints";
import { _get, _post } from "../services/HttpMethods";
import { constants } from "../constants/constants";
import { logoutErrors } from "../constants/ErrorMessage";
import { toast } from "react-toastify";
import { removeAuthData } from "../utils/common";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFormattedClientData } from "../utils/hookUtils";
import useNavigateHook from "./useNavigateHook";
export const useClient = () => {
  const token = useSelector((state) => state.auth.token);
  const { clients } = useSelector((state) => state.client);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleNavigate } = useNavigateHook();
  const [formattedClientsData, setFormattedClientsData] = useState([]);
  const [filters, setFilters] = useState({
    username: "",
    sorting: "",
  });

  const location = useLocation();

  const [disableBtn, setDisableBtn] = useState(true);
  useEffect(() => {
    setFormattedClientsData(
      getFormattedClientData(clients, navigate, dispatch, location?.pathname)
    );
  }, [clients]);

  const logoutFunc = () => {
    handleNavigate("/login");
  };

  const handleChangeFilter = (prop, value) => {
    setFilters((prev) => {
      return { ...prev, [prop]: value };
    });
    setDisableBtn(false);
  };

  const getClients = async (params = null, data) => {
    try {
      // GET request to fetch client data
      const response = await _get(
        endPoints.clients,
        params,
        token,
        data,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== responseCode._200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_CLIENT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
  };

  const createClient = async (data) => {
    let newObj = {
      name: data.Name,
      username: data.Username,
      password: data.Password,
      confirmPassword: data.RePassword,
    };
    try {
      // GET request to fetch client data
      const response = await _post(
        endPoints.clients,
        newObj,
        token,
        false,
        true,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (
        response.data.status !== responseCode._200 &&
        response.data.status !== responseCode._201
      ) {
        throw new Error(response.data.msg);
      }
      toast.success(response.data.msg);
      navigate("/clients");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSearch = () => {
    getClients(null, filters);
    setDisableBtn(true);
  };

  return {
    getClients,
    createClient,
    formattedClientsData,
    clients,
    filters,
    handleChangeFilter,
    disableBtn,
    handleSearch,
  };
};
