import { _post, _put } from "../services/HttpMethods";
import { endPoints } from "../services/EndPoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { removeAuthData, setAuthData } from "../utils/common";
import { useDispatch } from "react-redux";
import {
  CHANGE_PASSWORD_SUCCESS,
  LOGIN_SUCCESS,
} from "../redux/auth/actionType";
import { URLs } from "../routes/URL";
import { responseCode } from "../constants/responseCode";
import { useState } from "react";
import useNavigateHook from "./useNavigateHook";

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleNavigate } = useNavigateHook();

  const [loading, setLoading] = useState(false);
  const logoutFunc = () => {
    handleNavigate("/login");
  };

  const submitLogin = async (credentials) => {
    setLoading(true);
    try {
      const response = await _post(
        endPoints.userlogin,
        credentials,
        null,
        false,
        true
      );
      const { status, data, msg } = response?.data;
      if (status !== 200) {
        throw new Error(msg);
      }
      const authData = {
        token: data.token,
        userId: data.id,
        userName: data.username,
        permission: data.permission,
        role: data.role,
      };
      setLoading(false);
      setAuthData(authData);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: authData,
      });
      toast.success(msg);
      navigate(URLs.home);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const handleChangePassword = async (token, passwordApiRequest) => {
    setLoading(true);
    try {
      const response = await _put(
        endPoints.changePassword,
        passwordApiRequest,
        token,
        false,
        true
      );

      const { status, data, msg } = response?.data;

      if (status !== 200) {
        toast.error(msg);
      }

      setLoading(false);

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: data,
      });
      toast.success(msg);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const handleLogoutFunction = async (token, success) => {
    try {
      const response = await _post(
        endPoints.logout,
        null,
        token,
        false,
        true,
        logoutFunc
      );
      if (!response) {
        return;
      }
      const { data } = response;
      if (data.status !== responseCode._200) {
        throw new Error(data.msg);
      }
      toast.success(data.msg);
      if (success) {
        success();
      }
      removeAuthData();
    } catch (error) {
      toast.error(error.message);
      removeAuthData();
    }
  };
  return {
    submitLogin,
    handleLogoutFunction,
    loading,
    handleChangePassword,
  };
};
