export const chipStatementTableColumns = [
  {
    id: "no",
    numeric: true,
    disablePadding: true,
    label: "NO",
    // width: "20%",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "DATE",
    // width: "1%",
  },
  {
    id: "desc",
    numeric: false,
    disablePadding: true,
    label: "DESC",
    // width: "1%",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "TYPE",
    // width: "1%",
  },
  {
    id: "commIn",
    numeric: true,
    disablePadding: true,
    label: "COMM IN",
    // width: "1%",
  },
  {
    id: "commOut",
    numeric: true,
    disablePadding: true,
    label: "COMM OUT",
    // width: "1%",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: true,
    label: "AMOUNT",
    // width: "15%",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: true,
    label: "TOTAL",
    // width: "1%",
  },
  {
    id: "dc",
    numeric: false,
    disablePadding: true,
    label: "D/C",
    // width: "1%",
  },
  {
    id: "balance",
    numeric: true,
    disablePadding: true,
    label: "BALANCE",
    // width: "40%",
  },
];
