import { GET_CHIP_STATEMENT } from "./actionType";

const initialState = {
  chipStatement: [],
};

const chipStatementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHIP_STATEMENT:
      return {
        ...state,
        chipStatement: action.payload,
      };
    default:
      return state;
  }
};

export default chipStatementReducer;
