import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import clientReducer from "./clients/reducer";
import sportsReducer from "./sports/reducer";
import accountStatementReducer from "./accountStatement/reducer";
import sportReportReducer from "./sportReport/reducer";
import getChipSmryReducer from "./chipsmry/reducer";
import exportReducer from "./export/reducer";
import userReducer from "./user/reducer";
import betReducer from "./bets/reducer";
import chipStatementReducer from "./chipStatement/reducer";
import profileReducer from "./profile/reducer";
import supermasterReducer from "./supermaster/reducer";
import masterReducer from "./master/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  client: clientReducer,
  sports: sportsReducer,
  accountStatement: accountStatementReducer,
  sportReport: sportReportReducer,
  chipSmry: getChipSmryReducer,
  export: exportReducer,
  user: userReducer,
  bets: betReducer,
  chipStatement: chipStatementReducer,
  profile: profileReducer,
  supermaster: supermasterReducer,
  master: masterReducer
});

export default rootReducer;
