export const URLs = {
  login: "/login",
  home: "/",
  notFound: "*",
  createClient: "/create-client",
  createUser: "/create-user",
  clients: "/clients",
  profitLoss: "/account/profit-loss",
  accountStatement: "/account/account-statement",
  weeklyReport: "/account/weekly-report",
  profile: "/account/profile",
  chipSummary: "/chipSummary",
  sportReport: "/account/sport-report",
  settlementReport: "/settlement",
  export: "/account/export",
  withdraw: "/users/:id/bank-withdraw",
  deposit: "/users/:id/bank-deposit",
  betHistory: "/betHistory",
  profileEdit: "/users/:id/profit-edit",
  chipStatement: "/account/:id/chip-statement",
  supermasters: "/supermasters",
  masters: "/masters",
  event: "/event/:id"
};
