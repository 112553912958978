export const getAuthData = () => {
  return ["token", "userId", "userName", "permission", "role"].reduce(
    (authData, key) => {
      if (key === "permission") {
        try {
          const data = localStorage.getItem(key);
          authData[key] = data && data !== "undefined" ? JSON.parse(data) : {};
        } catch (error) {
          console.error("Error parsing JSON:", error);
          // Handle the error as needed, e.g., set parsedValue to a default value
        }
      } else {
        authData[key] = localStorage.getItem(key);
      }
      return authData;
    },
    {}
  );
};

export const setAuthData = ({ token, userId, userName, permission, role }) => {
  Object.entries({ token, userId, userName, permission, role }).forEach(
    ([key, value]) => {
      if (key === "permission") {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.setItem(key, value);
      }
    }
  );
};

export const removeAuthData = () => {
  ["token", "userId", "userName"].forEach((key) =>
    localStorage.removeItem(key)
  );
};

export const setSportsDetails = ({ sportsDetails }) => {
  sessionStorage.setItem("sportsDetails", JSON.stringify(sportsDetails));
};

export const getSportsDetails = () => {
  const sportsDetails =
    sessionStorage.getItem("sportsDetails") &&
    JSON.parse(sessionStorage.getItem("sportsDetails"));
  return { sportsDetails };
};

export const getCombinedMatchOddsData = (matches, odds) => {
  let combinedOddsArr = [];
  let newMatchesArr = JSON.parse(JSON.stringify(matches));
  for (let i = 1; i <= Object.keys(odds?.data).length; i++) {
    let key = "t" + i;
    combinedOddsArr = [...combinedOddsArr, ...odds.data[key]];
  }
  for (let i = 0; i < matches?.length; i++) {
    for (let j = 0; j < matches[i].matches?.length; j++) {
      let formattedObj = {};
      let oddsObject = {
        uback1: "-",
        lback1: "-",
        ulay1: "-",
        llay1: "-",
        uback2: "-",
        lback2: "-",
        ulay2: "-",
        llay2: "-",
        uback3: "-",
        lback3: "-",
        llay3: "-",
        ulay3: "-",
      };
      let sTime = "";
      for (let oddKey = 0; oddKey < combinedOddsArr?.length; oddKey++) {
        if (
          matches[i].matches[j].eventId ===
          combinedOddsArr[oddKey]?.gmid.toString()
        ) {
          sTime = combinedOddsArr[oddKey]?.stime;
          for (
            let sectionKey = 0;
            sectionKey < combinedOddsArr[oddKey]?.section?.length;
            sectionKey++
          ) {
            for (
              let oddsKey = 0;
              oddsKey <
              combinedOddsArr[oddKey]?.section[sectionKey].odds.length;
              oddsKey++
            ) {
              let sectionsOdd = combinedOddsArr[oddKey]?.section[sectionKey];
              if (sectionsOdd.odds[oddsKey].otype === "back") {
                let uOddsObjectKey = "uback" + sectionsOdd.sno;
                let lOddsObjectKey = "lback" + sectionsOdd.sno;
                oddsObject[uOddsObjectKey] = sectionsOdd.odds[oddsKey].odds;
                oddsObject[lOddsObjectKey] = Math.ceil(
                  sectionsOdd.odds[oddsKey].size
                );
              } else {
                let uOddsObjectKey = "ulay" + sectionsOdd.sno;
                let lOddsObjectKey = "llay" + sectionsOdd.sno;
                oddsObject[uOddsObjectKey] = sectionsOdd.odds[oddsKey].odds;
                oddsObject[lOddsObjectKey] = Math.ceil(
                  sectionsOdd.odds[oddsKey].size
                );
              }
            }
          }
        }
        formattedObj = {
          ...matches[i].matches[j],
          ...oddsObject,
          stime: sTime,
        };
      }
      newMatchesArr[i].matches[j] = formattedObj;
    }
  }
  return newMatchesArr;
};

export const getAllMatches = (data) => {
  const sportsMapping = {
    1: "FOOTBALL",
    2: "TENNIS",
    4: "CRICKET",
    2378961: "POLITICS",
    7: "HORSE RACING",
    4339: "GREYHOUND RACING",
    99994: "KABADDI",
    99999: "CASINO",
    99998: "INT CASINO",
    99990: "BINARY",
    26420387: "Mixed Martial Arts",
    998917: "Volleyball",
    7524: "Ice Hockey",
    7522: "Basketball",
    7511: "Baseball",
    3503: "Darts",
    29: "Futsal",
    20: "Table Tennis",
    5: "Rugby",
  };

  const eventGroups = {};
  data?.length &&
    data.forEach((event) => {
      if (event?.event_type_id?.N) {
        const eventType = event?.event_type_id?.N;
        const sportsName = sportsMapping?.[eventType] || "Lottery";
        const teamName = event?.short_name?.S;
        const eventName = event?.long_name?.S;
        const isPlay = event?.in_play?.N;
        const isLive = event?.active?.N;
        const isTV = event?.tv_id?.S;
        const teams = {};
        const eventId = event?.event_id?.N;
        const eventTypeId = event?.event_type_id?.N;
        const t11 = event?.t11;
        const t12 = event?.t12;
        const t21 = event?.t21;
        const t22 = event?.t22;
        const t31 = event?.t31;
        const t32 = event?.t32;
        const t41 = event?.t41;

        const t42 = event?.t42;
        const t51 = event?.t51;
        const t52 = event?.t52;
        const t61 = event?.t61;
        const t62 = event?.t61;

        // Format date and time
        const dateTime = new Date(event?.start_time?.S);
        const matchDate = dateTime.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
        });
        const matchTime = dateTime.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const match = {
          teamName: teamName,
          eventName: eventName,
          isPlay: isPlay,
          matchDate: matchDate,
          matchTime: matchTime,
          eventId: eventId,
          isLive: isLive,
          teams: teams,
          isTV: isTV,
          eventTypeId: eventTypeId,
          t11: t11,
          t12: t12,
          t21: t21,
          t22: t22,
          t31: t31,
          t32: t32,
          t41: t41,
          t42: t42,
          t51: t51,
          t52: t52,
          t61: t61,
          t62: t62,
        };

        if (!eventGroups[sportsName]) {
          eventGroups[sportsName] = {
            sportsName: sportsName,
            matches: [],
          };
        }
        eventGroups[sportsName].matches.push(match);
      }
    });
  let formattedSport = Object.values(eventGroups).sort((a, b) => {
    if (a.sportsName === "CRICKET") return -1;
    if (b.sportsName === "CRICKET") return 1;
    return 0;
  });
  return Object.values(formattedSport);
};

export function sortByCreatedDate(data) {
  return data.sort(function (a, b) {
    var dateA = new Date(a?.start_time?.S);
    var dateB = new Date(b?.start_time?.S);
    return dateB - dateA;
  });
}

export const timeConversionForReactDatePicker = (dateString) => {
  const date = new Date(dateString);

  // Get year, month, and day
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, "0");

  // Format as YYYY-MM-DD
  return `${year}-${month}-${day}`;
};

export const fourDaysBackDate = (val = 4) => {
  const date = new Date(); // Current date
  date.setDate(date.getDate() - val); // Subtract days
  return date;
};

export function convertTimestampToDate(timestamp) {
  // Convert microseconds to milliseconds
  const milliseconds = parseInt(timestamp, 10) / 1e3;

  // Create a Date object from the milliseconds
  const date = new Date(milliseconds);

  // Format the date to "DD/MM/YYYY hh:mm:ss AM/PM"
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();

  const hours24 = date.getHours();
  const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const ampm = hours24 >= 12 ? "PM" : "AM";

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year} ${hours12}:${minutes}:${seconds} ${ampm}`;

  return formattedDate;
}

export const getFormattedInPlayData = (matchData) => {
  let newMatchesArr = [];
  for (let i = 0; i < matchData.length; i++) {
    let elem = {};
    elem["sportsName"] = matchData[i]?.sportsName;
    elem["matches"] = [];
    for (let j = 0; j < matchData[i].matches.length; j++) {
      if (matchData[i].matches[j]?.isLive === "1") {
        elem["matches"].push(matchData[i].matches[j]);
      }
    }
    newMatchesArr.push(elem);
  }
  return newMatchesArr;
};

export function formatInnerOddsData(data) {
  const results = []
  let parsedData = []
  if (typeof data === 'string') {
    parsedData = JSON.parse(data)
  }

  parsedData.forEach((item) => {
    const result = {}

    result.team_name_matched = item.team_name_matched
    result.mtype = item.mtype
    result.marketId = item.marketId
    result.bet_min = item.bet_min
    result.bet_max = item.bet_max
    result.locked = item.locked
    result.inPlay = item.inPlay
    result.active = item.active
    result.eventId = item.eventId
    result.delay = item.delay

    result.teamData = {}
    const runnersObj = item.runners.obj

    result.teamData = {
      name1: runnersObj.name1,
      name1full: runnersObj.name1full,
      back1: runnersObj.back1,
      lay1: runnersObj.lay1,
      name2: runnersObj.name2,
      name2full: runnersObj.name2full,
      back2: runnersObj.back2,
      lay2: runnersObj.lay2,
      fav: runnersObj.fav,
    }

    result.loop = item.runners.loop
    results.push(result)
  })

  return results
}
