import React, { useEffect, useRef, useState } from "react";
import EventDetails from "./EventDetails";
import { io } from "socket.io-client";
import { formatInnerOddsData } from "../../utils/common";
import EventScoreCard from "./EventScoreCard";

const EventContent = ({ matchData }) => {
  const [innerMatchDetails, setInnerMatchDetails] = useState({});
  const [innerMatchFancy, setInnerMatchFancy] = useState({});
  const [innerMatchCombined, setInnerMatchCombined] = useState({});
  const [scoreData, setScoreData] = useState({});
  const avoidRerender = useRef(false);
  const socketTimeout = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setInnerMatchCombined({ ...innerMatchDetails, ...innerMatchFancy });
  }, [innerMatchFancy, innerMatchDetails]);

  useEffect(() => {
    const socket = io("https://odds.100panel.org", {
      transports: ["websocket"],
    });

    if (socket) {
      if (socketTimeout.current) {
        clearTimeout(socketTimeout.current);
      }
      if (!avoidRerender.current) {
        avoidRerender.current = true;
        const scoreSocket = io("v4score.bet99.io", {
          transports: ["websocket"],
        });
        scoreSocket.emit("score", matchData.eventId);
        scoreSocket.on("score", (data) => {
          setScoreData(data);
        });
        socket.emit("event", matchData.eventId);

        socket.on(`match_odds_${matchData.eventId}`, (data) => {
          let formattedData = formatInnerOddsData(data);
          let key =
            formattedData[0].mtype === "bm1" ? "BOOKMAKER" : "MATCH ODDS";
          setInnerMatchDetails({ [key]: formattedData });
        });
        socket.on(`admin_session_active_${matchData.eventId}`, (data) => {
          setInnerMatchFancy({ FANCY: data });
        });
      }
      return () => {
        avoidRerender.current = true;
      };
    }
  }, []);
  return (
    <div className="">
      <div className="bg-[#CC9647] p-3">
        <span className="text-white">{matchData.teamName}</span>
        <span className="ml-[5px] p-1 bg-[green] font-bold text-white rounded-sm">
          BETS
        </span>
      </div>
      <div className="flex justify-around items-start">
        <div
          style={{
            width: windowWidth > 994 ? "65%" : "100%",
          }}
        >
          {Object.keys(innerMatchCombined).length > 0 &&
            Object.values(innerMatchCombined).map((item, indx) => {
              return (
                <EventDetails
                  itemDetails={item}
                  titleName={Object.keys(innerMatchCombined)[indx]}
                />
              );
            })}
        </div>
        {windowWidth > 994 && (
          <div className="w-[33%] pt-[5px]">
            <EventScoreCard scoreData={scoreData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default EventContent;
