import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { _put } from "../services/HttpMethods";
import { endPoints } from "../services/EndPoints";
import { UPDATE_CLIENT_SUCCESS } from "../redux/clients/actionType";
import { IoPersonSharp } from "react-icons/io5";
import { MdFace6, MdOutlineGroup, MdOutlineShowChart } from "react-icons/md";
import { AiOutlineCreditCard } from "react-icons/ai";
import { PiCurrencyDollarBold } from "react-icons/pi";
import { useClient } from "./client-hook";

export const useEditProfile = () => {
  const token = useSelector((state) => state.auth.token);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clients = useSelector((state) => state.client.clients);
  const client = clients?.find((item) => item.clientId === id);
  const [clientData, setClientData] = useState(client || {});
  const [formattedProfileData, setFormattedProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getClients } = useClient();

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    setFormattedProfileData(getFormattedProfileData(clientData));
  }, [clientData]);

  const getFormattedProfileData = (client) => [
    {
      icon: <MdFace6 color="green" fontSize="24px" />,
      title: "Name",
      value: client.name,
    },
    {
      icon: <IoPersonSharp color="green" fontSize="24px" />,
      title: "Username",
      value: client.username,
    },
    {
      icon: <AiOutlineCreditCard color="green" fontSize="24px" />,
      title: "Free Chip",
      value: client.downLineCredit,
    },
    {
      icon: <PiCurrencyDollarBold color="green" fontSize="24px" />,
      title: "P/L",
      value: client.pl,
    },
    {
      icon: <MdOutlineShowChart color="green" fontSize="24px" />,
      title: "Expose",
      value: client.upLinePL,
    },

    //below code need adjustment when we will have proper data
    ...[
      "Cricket",
      "Football",
      "Tennis",
      "Horse",
      "Greyhound",
      "Politics",
      "Casino",
      "iCasino",
    ].map((sport) => ({
      icon: <MdOutlineGroup color="green" fontSize="24px" />,
      title: `${sport} Client Share`,
      value: client.totalClients,
    })),
  ];

  const logoutFunc = () => navigate("/login");

  const handleUpdateClient = async (updatedFields) => {
    setLoading(true);
    try {
      const response = await _put(
        endPoints.clients + "/" + id,
        updatedFields,
        token,
        false,
        true,
        logoutFunc
      );
      const { status, data, msg } = response.data;

      if (status === 200) {
        dispatch({ type: UPDATE_CLIENT_SUCCESS, payload: data });
        toast.success(msg);
      } else {
        toast.error(msg);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClient = (updates) => {
    const updatedFields = {
      name: updates.name,
      creditLimit: updates.creditLimit,
      ...updates,
    };

    setClientData((prev) => ({ ...prev, ...updates }));
    handleUpdateClient(updatedFields);
  };

  return {
    formattedProfileData,
    loading,
    clientData,
    setClientData,
    handleEditClient,
  };
};
