import React from "react";

const Tabs = ({ sports, onTabChange, view }) => {
  return (
    <div>
      <ul className="h-auto bg-[#ffffff] w-[100%]  ">
        {sports?.length ? (
          sports?.map((item, id) => {
            return (
              <li
                key={item?.name + id}
                className="w-[100%] mx-auto  p-0 bg-[#ffffff] font=[10px] relative"
                onClick={item.disable ? () => {} : () => onTabChange(item)}
              >
                <a
                  className={`flex flex-col justify-center items-center border-b border-gray-200 py-2.5 text-black text-[12px] uppercase  hover:bg-[#cc9647] hover:text-white cursor-pointer ${
                    item?.name == "IN-PLAY" && "animate-blink-inplay "
                  } active: ${item?.name == view && "bg-[#cc9647]"}`}
                >
                  <img className="w-[15px] block m-0" src={item?.imageUrl} />
                  {item?.name}
                  {item?.count && (
                    <div className="gameCount absolute top-0.5 right-1 font-extrabold text-[9px] leading-[17px] w-[17px] h-[17px] bg-green-500 rounded-full text-white pl-[3px]">
                      {item?.count}
                    </div>
                  )}
                </a>
              </li>
            );
          })
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
};

export default Tabs;
