import { GET_SUPERMASTER_DATA } from "./actionType";

const initialState = {
  supermaster: [],
};

const supermasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPERMASTER_DATA:
      return {
        ...state,
        supermaster: action.payload,
      };
    default:
      return state;
  }
};

export default supermasterReducer;
