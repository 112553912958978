import React from "react";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import { constants } from "../../constants/constants";
import { sportsArr, typeArr } from "./constants";
import SearchButton from "../../components/SearchButton";

const SearchForm = ({
  accStatFilters,
  handleFilterChanges,
  handleSearch,
  disableBtn,
}) => {
  return (
    <div className="flex items-center gap-[20px] mb-[20px] bg-[white] p-[10px] border border-[#CC9647]">
      <div className="w-[20%]">
        <SearchInputs
          field={constants.selectField}
          label={constants.Sport}
          value={accStatFilters.sports}
          onChange={(e) => handleFilterChanges("sports", e.target.value)}
          optionArr={sportsArr}
          id={"sport-select"}
        />
      </div>
      <div className="w-[20%]">
        <SearchInputs
          field={constants.selectField}
          label={constants.Type}
          value={accStatFilters.type}
          onChange={(e) => handleFilterChanges("type", e.target.value)}
          optionArr={typeArr}
          id={"type-select"}
        />
      </div>

      <div className="w-[20%]">
        <SearchInputs
          field={constants.dateField}
          label={constants.StartDate}
          value={accStatFilters.startDate}
          onChange={(e) => handleFilterChanges("startDate", e)}
          id={"start-date"}
        />
      </div>
      <div className="w-[20%]">
        <SearchInputs
          field={constants.dateField}
          label={constants.EndDate}
          value={accStatFilters.endDate}
          onChange={(e) => handleFilterChanges("endDate", e)}
          id={"end-date"}
        />
      </div>
      <SearchButton handleSearch={handleSearch} disableBtn={disableBtn}/>
    </div>
  );
};

export default SearchForm;
