import Swal from "sweetalert2";
import { toast } from "react-toastify";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EditIcon from "@mui/icons-material/Edit";
import { convertTimestampToDate, removeAuthData } from "./common";
import { URLs } from "../routes/URL";
import { _get, _put } from "../services/HttpMethods";
import { endPoints } from "../services/EndPoints";
import { responseCode } from "../constants/responseCode";
import { constants } from "../constants/constants";
import { logoutErrors } from "../constants/ErrorMessage";
import { GET_CLIENT_SUCCESS } from "../redux/clients/actionType";
import { GET_SUPERMASTER_DATA } from "../redux/supermaster/actionType";
import { GET_MASTER_SUCCESS } from "../redux/master/actionType";

const name = localStorage.getItem("userName");
const token = localStorage.getItem("token");

const handleResetpassword = async (id, navigate, token, role) => {
  const params =
    role === "3"
      ? `supermaster/${id}/reset-password`
      : role === "2"
      ? `master/${id}/reset-password`
      : `clients/${id}/reset-password`;
  try {
    const response = await _put(params, {}, token, false, true, () =>
      navigate("/login")
    );
    console.log(response, "responseHere");
    if (!response) {
      return;
    } else if (response.data.status !== responseCode._200) {
      throw new Error(response.data.msg);
    }
    toast.success(response.data.msg + " : " + response.data.data.password);
  } catch (error) {
    if (error.message === constants.Unauthorized) {
      removeAuthData();
      toast.info(logoutErrors.sessionExpire);
    } else {
      toast.error(error.message);
    }
  }
};

const handleReFetchClient = async (params, data, token, navigate, dispatch) => {
  try {
    const response = await _get(
      endPoints.clients,
      params,
      token,
      data,
      false,
      () => navigate("/login")
    );
    if (!response) {
      return;
    } else if (response.status !== responseCode._200) {
      throw new Error(response.msg);
    }
    dispatch({
      type: GET_CLIENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error.message === constants.Unauthorized) {
      removeAuthData();
      toast.info(logoutErrors.sessionExpire);
    } else {
      toast.error(error.message);
    }
  }
};

const handleRefetchMaster = async (
  params,
  data,
  token,
  navigate,
  dispatch,
  id,
  parentId
) => {
  const loggedInRole = localStorage.getItem("role");
  const url =
    loggedInRole === "9" ? `supermaster/${parentId}/master-list` : `/master`;
  try {
    const response = await _get(url, params, token, data, false, () =>
      navigate("/login")
    );
    if (!response) {
      return;
    } else if (response.status !== responseCode._200) {
      throw new Error(response.msg);
    }
    dispatch({
      type: GET_MASTER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error.message === constants.Unauthorized) {
      removeAuthData();
      toast.info(logoutErrors.sessionExpire);
    } else {
      toast.error(error.message);
    }
  }
};

const handleRefetchSupermaster = async (
  params,
  data,
  token,
  navigate,
  dispatch
) => {
  try {
    // GET request to fetch client data
    const response = await _get(
      endPoints.supermaster,
      params,
      token,
      data,
      false,
      () => navigate("/login")
    );
    if (!response) {
      return;
    } else if (response.status !== responseCode._200) {
      throw new Error(response.msg);
    }
    dispatch({
      type: GET_SUPERMASTER_DATA,
      payload: response.data,
    });
  } catch (error) {
    if (error.message === constants.Unauthorized) {
      removeAuthData();
      toast.info(logoutErrors.sessionExpire);
    } else {
      toast.error(error.message);
    }
  }
};

const handleLockBet = async (id, navigate, value, token, dispatch) => {
  const params = `/${id}/bet/lock?locked=${value}`;
  try {
    const response = await _put(
      endPoints.clients + params,
      params,
      token,
      false,
      true,
      () => navigate("/login")
    );
    if (!response) {
      return;
    } else if (response.status !== responseCode._200) {
      throw new Error(response.msg);
    }

    handleReFetchClient(null, null, token, navigate, dispatch);
    toast.success(response?.data.msg);
  } catch (error) {
    if (error.message === constants.Unauthorized) {
      removeAuthData();
      toast.info(logoutErrors.sessionExpire);
    } else {
      toast.error(error.message);
    }
  }
};

const getTableData = (
  param,
  arg2,
  arg3,
  token,
  navigate,
  dispatch,
  id,
  parentId
) => {
  switch (param) {
    case "/supermasters":
      return handleRefetchSupermaster(arg2, arg3, token, navigate, dispatch);
    case "/masters":
      return handleRefetchMaster(
        arg2,
        arg3,
        token,
        navigate,
        dispatch,
        id,
        parentId
      );
    case "/clients":
      return handleReFetchClient(arg2, arg3, token, navigate, dispatch);
    default:
      break;
  }
};

const handleLockStatus = async (
  id,
  navigate,
  value,
  token,
  dispatch,
  role,
  param,
  parentId = null
) => {
  const params =
    role === "3"
      ? `supermaster/${id}/status?active=${value}`
      : role === "2"
      ? `master/${id}/status?active=${value}`
      : `clients/${id}/status?active=${value}`;
  try {
    const response = await _put(
      params,
      params,
      token,
      false,
      true
      // () =>navigate("/login")
    );
    if (!response) {
      return;
    } else if (response.data.status !== responseCode._200) {
      throw new Error(response.data.msg);
    }

    getTableData(param, null, null, token, navigate, dispatch, id, parentId);
    toast.success(response?.data?.msg);
  } catch (error) {
    if (error.message === constants.Unauthorized) {
      removeAuthData();
      toast.info(logoutErrors.sessionExpire);
    } else {
      toast.error(error.message);
    }
  }
};

export const getFormattedClientData = (
  clientsArr = [],
  navigate,
  dispatch,
  param
) => {
  let newArr = [];
  for (let i = 0; i < clientsArr?.length; i++) {
    let item = {
      username: {
        label: clientsArr[i]?.username,
        key: "username",
        type: "value",
        style: {
          fontWeight: "bold",
        },
      },
      balance: {
        label: parseInt(clientsArr[i]?.limit.current),
        key: "balance",
        type: "value",
      },
      pl: {
        label: parseInt(clientsArr[i]?.pl),
        key: "pl",
        type: "value",
      },
      exp: {
        label: 0,
        key: "exp",
        type: "value",
      },
      clientShare: {
        label: 0,
        key: "clientShare",
        type: "value",
      },
      upLine: {
        label: name,
        key: "upLine",
        type: "value",
        style: {
          fontWeight: "bold",
        },
      },
      status: {
        label: (
          <LockOpenIcon
            onClick={() =>
              Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                html: `User will be ${
                  clientsArr[i].active === true ? "Inactive!" : "Active!"
                }`,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: `OK`,
                confirmButtonAriaLabel: "Thumbs up, great!",
                cancelButtonText: `Cancel`,
                cancelButtonAriaLabel: "Thumbs down",
              }).then((result) => {
                if (result?.isConfirmed) {
                  handleLockStatus(
                    clientsArr[i].id,
                    navigate,
                    !clientsArr[i].active,
                    token,
                    dispatch,
                    clientsArr[i].role,
                    param
                  );
                }
              })
            }
          />
        ),
        key: "status",
        type: "icon",
        btnStyle: {
          background: "none",
          color: clientsArr[i].active ? "green" : "red",
        },
      },
      bet: {
        label: (
          <LockOpenIcon
            onClick={() =>
              Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                html: `Bet will be ${
                  clientsArr[i].locked === true ? "Open" : "locked"
                } for this User!`,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: `OK`,
                confirmButtonAriaLabel: "Thumbs up, great!",
                cancelButtonText: `Cancel`,
                cancelButtonAriaLabel: "Thumbs down",
              }).then((result) => {
                if (result?.isConfirmed) {
                  handleLockBet(
                    clientsArr[i].clientId,
                    navigate,
                    !clientsArr[i].locked,
                    token,
                    dispatch
                  );
                }
              })
            }
          />
        ),
        key: "bet",
        type: "icon",
        btnStyle: {
          background: "none",
          color: clientsArr[i].locked ? "red" : "green",
        },
      },
      options: {
        label: [
          {
            icon: <EditIcon />,
            onClick: () =>
              navigate(`/users/${clientsArr[i].clientId}/profit-edit`, {
                state: {
                  arr: clientsArr[i],
                },
              }),
          },
          {
            icon: "BANK DEPOSIT",
            onClick: () =>
              navigate(`/users/${clientsArr[i].clientId}/bank-deposit`, {
                state: {
                  arr: clientsArr[i],
                },
              }),
          },
          {
            icon: "BANK WITHDRAW",
            onClick: () =>
              navigate(`/users/${clientsArr[i].clientId}/bank-withdraw`, {
                state: {
                  arr: clientsArr[i],
                },
              }),
          },
          {
            icon: "ACCOUNT STATEMENT",
            onClick: () =>
              navigate(URLs.accountStatement, {
                state: {
                  arr: clientsArr[i],
                },
              }),
          },
          {
            icon: "PENDING BETS",
            onClick: () =>
              navigate(`/betHistory`, {
                state: {
                  arr: clientsArr[i],
                },
              }),
          },
          {
            icon: "RESET PASSWORD",
            onClick: () =>
              Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                html: `Password of ${name} will be reset to 123456!`,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: `OK`,
                confirmButtonAriaLabel: "Thumbs up, great!",
                cancelButtonText: `Cancel`,
                cancelButtonAriaLabel: "Thumbs down",
              }).then((result) => {
                if (result?.isConfirmed) {
                  handleResetpassword(
                    clientsArr[i].id,
                    navigate,
                    token,
                    clientsArr[i].role
                  );
                }
              }),
          },
          {
            icon: ":REPORTS",
            onClick: () => localStorage.setItem("optionItem", clientsArr[i]),
          },
        ],
        key: "options",
        type: "mutipleButtons",
        btnStyle: {
          backgroundColor: "#003559",
          padding: "5px",
          fontSize: "12px",
        },
      },
      originalArrData: clientsArr[i],
    };
    newArr.push(item);
  }
  return newArr;
};

export const getFormattedSupermasterData = (
  dataArr = [],
  navigate,
  dispatch,
  location
) => {
  console.log(dataArr, "dataArrHere");
  let newArr = [];
  for (let i = 0; i < dataArr?.length; i++) {
    let item = {
      username: {
        label: dataArr[i]?.username,
        key: "username",
        type: "value",
        style: {
          fontWeight: "bold",
        },
      },
      balance: {
        label: parseInt(dataArr[i]?.current),
        key: "balance",
        type: "value",
      },
      pl: {
        label: parseInt(dataArr[i]?.pl),
        key: "pl",
        type: "value",
      },
      share: {
        label: parseInt(dataArr[i]?.share),
        key: "share",
        type: "value",
      },
      admin: {
        label: dataArr[i]?.addedBy?.username,
        key: "admin",
        type: "value",
      },
      status: {
        label: (
          <LockOpenIcon
            onClick={async () => {
              const result = await Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                html: `User will be ${
                  dataArr[i].active === true ? "Inactive!" : "Active!"
                }`,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: `OK`,
                confirmButtonAriaLabel: "Thumbs up, great!",
                cancelButtonText: `Cancel`,
                cancelButtonAriaLabel: "Thumbs down",
              });
              if (result?.isConfirmed) {
                handleLockStatus(
                  dataArr[i].id,
                  navigate,
                  !dataArr[i].active,
                  token,
                  dispatch,
                  dataArr[i].role,
                  location?.pathname,
                  location?.state?.arr?.id
                );
              }
            }}
          />
        ),
        key: "status",
        type: "icon",
        btnStyle: {
          background: "none",
          color: dataArr[i].active ? "green" : "red",
        },
      },
      options: {
        label: [
          // {
          //   icon: <EditIcon />,
          //   onClick: () =>
          //     navigate(`/users/${dataArr[i]?.id}/profit-edit`, {
          //       state: {
          //         arr: dataArr[i],
          //       },
          //     }),
          // },
          {
            icon: "BANK DEPOSIT",
            onClick: () =>
              navigate(`/users/${dataArr[i]?.id}/bank-deposit`, {
                state: {
                  arr: dataArr[i],
                  role: dataArr[i].role === "3" ? "supermaster" : "master",
                },
              }),
          },
          {
            icon: "BANK WITHDRAW",
            onClick: () =>
              navigate(`/users/${dataArr[i]?.id}/bank-withdraw`, {
                state: {
                  arr: dataArr[i],
                  role: dataArr[i].role === "3" ? "supermaster" : "master",
                },
              }),
          },
          {
            icon: "ACCOUNT STATEMENT",
            onClick: () =>
              navigate(URLs.accountStatement, {
                state: {
                  arr: dataArr[i],
                  role: dataArr[i].role === "3" ? "supermaster" : "master",
                },
              }),
          },
          // {
          //   icon: "PENDING BETS",
          //   onClick: () =>
          //     navigate(`/betHistory`, {
          //       state: {
          //         arr: dataArr[i],
          //         role:"admin"
          //       },
          //     }),
          // },
          {
            icon: "RESET PASSWORD",
            onClick: () =>
              Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                // html: `Password of ${name} will be reset to 123456!`,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: `OK`,
                confirmButtonAriaLabel: "Thumbs up, great!",
                cancelButtonText: `Cancel`,
                cancelButtonAriaLabel: "Thumbs down",
              }).then((result) => {
                if (result?.isConfirmed) {
                  handleResetpassword(
                    dataArr[i].id,
                    navigate,
                    token,
                    dataArr[i].role
                  );
                }
              }),
          },
          {
            icon: ":REPORTS",
            onClick: () => localStorage.setItem("optionItem", dataArr[i]),
          },
        ],
        key: "options",
        type: "mutipleButtons",
        btnStyle: {
          backgroundColor: "#003559",
          padding: "5px",
          fontSize: "12px",
        },
      },
      originalArrData: dataArr[i],
      arrData: dataArr[i].role === "3" ? "supermaster" : "master",
    };
    newArr.push(item);
  }
  return newArr;
};

export const getFormattedBetData = (betsArr) => {
  let newArr = [];
  for (let i = 0; i < betsArr?.length; i++) {
    let item = {
      no: {
        label: i + 1,
        key: "no",
        type: "value",
      },
      user: {
        label: betsArr[i].username,
        key: "user",
        type: "value",
      },
      event: {
        label: betsArr[i].event.long_name,
        key: "event",
        type: "value",
      },
      runner: {
        label: betsArr[i].runner,
        key: "runner",
        type: "value",
      },
      rate: {
        label: parseInt(betsArr[i].rate),
        key: "rate",
        type: "value",
      },
      stake: {
        label: betsArr[i].amount,
        key: "stake",
        type: "value",
      },
      dateTime: {
        label: convertTimestampToDate(betsArr[i].created),
        key: "dateTime",
        type: "value",
      },
      profit: {
        label: betsArr[i].profit || 0,
        key: "profit",
        type: "value",
      },
      loss: {
        label: betsArr[i].loss || 0,
        key: "loss",
        type: "value",
      },
      ip: {
        label: betsArr[i].ip || 0,
        key: "ip",
        type: "value",
      },
      commIn: {
        label: betsArr[i].commIn || 0,
        key: "commIn",
        type: "value",
      },
      commOut: {
        label: betsArr[i].commOut || 0,
        key: "commOut",
        type: "value",
      },
    };
    newArr.push(item);
  }
  return newArr;
};

export const getAccountStatementData = (accountStatArr = []) => {
  let newArr = [];
  for (let i = 0; i < accountStatArr?.length; i++) {
    let item = {
      no: {
        label: i + 1,
        key: "no",
        type: "value",
      },
      date: {
        label: convertTimestampToDate(accountStatArr[i]?.created),
        key: "date",
        type: "value",
      },
      desc: {
        label: accountStatArr[i]?.desc,
        key: "desc",
        type: "value",
      },
      commIn: {
        label: 0,
        key: "commIn",
        type: "value",
      },
      commOut: {
        label: 0,
        key: "commOut",
        type: "value",
      },
      amount: {
        label: accountStatArr[i]?.amount,
        key: "amount",
        type: "value",
      },
      total: {
        label: accountStatArr[i]?.amount,
        key: "total",
        type: "value",
        style: {
          color: accountStatArr[i]?.amount > 0 ? "green" : "red",
          fontWeight: "bold",
        },
      },
      dc: {
        label: accountStatArr[i]?.debitCredit,
        key: "dc",
        type: "value",
      },
      balance: {
        label: 0,
        key: "balance",
        type: "value",
        style: {
          fontWeight: "bold",
        },
      },
      details: {
        label: 0,
        key: "details",
        type: "value",
      },
      originalArrData: accountStatArr[i],
    };
    newArr.push(item);
  }
  return newArr;
};

export const getFormattedData = (
  clientsArr = [],
  config,
  showSerialNumber = true
) => {
  return clientsArr.map((client, index) => {
    let item = {};
    for (let key in config) {
      const fieldConfig = config[key];

      let value;
      if (typeof fieldConfig.value === "function") {
        value = fieldConfig.value(client);
      } else {
        value = client[fieldConfig.field];
      }

      if (showSerialNumber) {
        item["serialNumber"] = {
          label: index + 1,
          key: "serialNumber",
          type: "value",
        };
      }
      item[key] = {
        label: value,
        key: key,
        type: fieldConfig.type,
        style: fieldConfig.style || {},
        btnStyle: fieldConfig.btnStyle || {},
      };

      if (fieldConfig.type === "icon") {
        item[key].label = fieldConfig.icon;
      } else if (fieldConfig.type === "multipleButtons") {
        item[key].label = fieldConfig.buttons;
      }
    }

    item.originalArrData = client;

    return item;
  });
};

export const getFormattedChipData = (chipStatement) => {
  let newArr = [];
  for (let i = 0; i < chipStatement?.length; i++) {
    let item = {
      no: {
        label: i + 1,
        key: "no",
        type: "value",
      },
      date: {
        label: convertTimestampToDate(chipStatement[i]?.created),
        key: "date",
        type: "value",
      },
      desc: {
        label: chipStatement[i]?.desc,
        key: "desc",
        type: "value",
      },
      type: {
        label: chipStatement[i]?.type,
        key: "type",
        type: "value",
      },
      commIn: {
        label: 0,
        key: "commIn",
        type: "value",
      },
      commOut: {
        label: 0,
        key: "commOut",
        type: "value",
      },
      amount: {
        label: chipStatement[i]?.amount,
        key: "amount",
        type: "value",
      },
      total: {
        label: chipStatement[i]?.amount,
        key: "total",
        type: "value",
        style: {
          color: chipStatement[i]?.amount > 0 ? "green" : "red",
          fontWeight: "bold",
        },
      },
      dc: {
        label: chipStatement[i]?.debitCredit,
        key: "dc",
        type: "value",
      },
      balance: {
        label: 0,
        key: "balance",
        type: "value",
        style: {
          fontWeight: "bold",
        },
      },
      // originalArrData: chipStatement[i],
    };
    newArr.push(item);
  }
  return newArr;
};
