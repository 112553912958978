import { useDispatch, useSelector } from "react-redux";
import { endPoints } from "../services/EndPoints";
import { _get, _post } from "../services/HttpMethods";
import { responseCode } from "../constants/responseCode";
import useNavigateHook from "./useNavigateHook";
import { error } from "../constants/errors";
import { constants } from "../constants/constants";
import { removeAuthData } from "../utils/common";
import { toast } from "react-toastify";
import { logoutErrors } from "../constants/ErrorMessage";
import { GET_SUPERMASTER_OPTIONS } from "../redux/user/actionTypes";

export const useUser = () => {
  const { handleNavigate } = useNavigateHook();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const { supermasterOption } = useSelector((state) => state.user);
  const logoutFunc = () => {
    handleNavigate("/login");
  };

  const createUser = async (data) => {
    try {
      const response = await _post(
        endPoints.user,
        {
          username: data.Username,
          name: data.Name,
          password: data.Password,
          confirmPassword: data.ConfirmPassword,
          phone: data.MobileNumber,
          role: data.Role,
          share: parseInt(data.Share),
          ...(data.Parent && { parentId: data.Parent }),
        },
        token,
        false,
        true,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.data.status !== responseCode._200) {
        throw new Error(response.data.msg);
      }
      toast.success(response.data.msg);
    } catch {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
  };

  const getSupermasterOptions = async () => {
    try {
      // GET request to fetch client data
      const response = await _get(
        endPoints.supermaster,
        null,
        token,
        null,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== responseCode._200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_SUPERMASTER_OPTIONS,
        payload: response.data,
      });
    } catch {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
  };
  return {
    getSupermasterOptions,
    supermasterOption,
    createUser,
  };
};
