import React from "react";
import HomeSports from "./HomeSports";
import Inplay from "./Inplay";
import Cricket from "./Cricket";
import Tennis from "./Tennis";
import Football from "./Football";
import Politics from "./Politics";
import HorseRacing from "./HorseRacing";
import GreyHoundRacing from "./GreyHoundRacing";
import Kabaddi from "./Kabaddi";
import Baseball from "./Baseball";
import Darts from "./Darts";
import Futsal from "./Futsal";
import TableTennis from "./TableTennis";
import Rugby from "./Rugby";
import Casino from "./Casino";
import SportsBook from "./SportsBook";
import IntCasino from "./IntCasino";
import Binary from "./Binary";
import MixedMartialArts from "./MixedMartialArts";
import Volleyball from "./Volleyball";
import IceHockey from "./IceHockey";
import Basketball from "./Basketball";
import { SocketProvider } from "../../SocketContext";

const AllSports = ({ view, matchData }) => {
  function RenderComponent() {
    switch (view) {
      case "HOME":
        return <HomeSports matchData={matchData} view={view} />;
      case "IN-PLAY":
        return <Inplay matchData={matchData} view={view} />;
      case "CRICKET":
        return <Cricket matchData={matchData} view={view} />;
      case "TENNIS":
        return <Tennis matchData={matchData} view={view} />;
      case "FOOTBALL":
        return <Football matchData={matchData} view={view} />;
      case "POLITICS":
        return <Politics matchData={matchData} view={view} />;
      case "HORSE RACING":
        return <HorseRacing matchData={matchData} view={view} />;
      case "GREYHOUND RACING":
        return <GreyHoundRacing matchData={matchData} view={view} />;
      case "KABADDI":
        return <Kabaddi matchData={matchData} view={view} />;
      case "CASINO":
        return <Casino />;
      case "SPORTS BOOK":
        return <SportsBook />;
      case "INT CASINO":
        return <IntCasino />;
      case "BINARY":
        return <Binary />;
      case "MIXED MARTIAL ARTS":
        return <MixedMartialArts />;
      case "VOLLEYBALL":
        return <Volleyball matchData={matchData} view={view} />;
      case "ICE HOCKEY":
        return <IceHockey matchData={matchData} view={view} />;
      case "BASKETBALL":
        return <Basketball matchData={matchData} view={view} />;
      case "BASEBALL":
        return <Baseball matchData={matchData} view={view} />;
      case "DARTS":
        return <Darts />;
      case "FUTSAL":
        return <Futsal />;
      case "TABLE TENNIS":
        return <TableTennis matchData={matchData} view={view} />;
      case "RUGBY":
        return <Rugby matchData={matchData} view={view} />;

      default:
        <></>;
    }
  }
  return (
    <SocketProvider>
      <RenderComponent />
    </SocketProvider>
  );
};

export default AllSports;
