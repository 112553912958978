import { useEffect, useState } from "react";
import {
  fourDaysBackDate,
  removeAuthData,
  timeConversionForReactDatePicker,
} from "../utils/common";
import { _get } from "../services/HttpMethods";
import { endPoints } from "../services/EndPoints";
import { useDispatch, useSelector } from "react-redux";
import useNavigateHook from "./useNavigateHook";
import { responseCode } from "../constants/responseCode";
import { toast } from "react-toastify";
import { constants } from "../constants/constants";
import { logoutErrors } from "../constants/ErrorMessage";
import { GET_CHIP_STATEMENT } from "../redux/chipStatement/actionType";
import { getFormattedChipData } from "../utils/hookUtils";

export const useChipStatement = () => {
  const { chipStatement } = useSelector((state) => state.chipStatement);
  const dispatch = useDispatch();
  const { handleNavigate } = useNavigateHook();
  const [chipFilters, setChipFilters] = useState({
    startDate: fourDaysBackDate(),
    endDate: new Date(),
  });
  const [btnDisable, setBtnDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [chipData, setChipData] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const handleFilterChanges = (prop, value) => {
    setChipFilters((prev) => {
      return { ...prev, [prop]: value };
    });
    setBtnDisable(false);
  };
  const logoutFunc = () => {
    handleNavigate("/login");
  };

  useEffect(()=>{
    setChipData(getFormattedChipData(chipStatement))
  },[chipStatement])

  const getChipStatement = async (clientData, data) => {
    setLoading(true);
    let formatObj = {
      ...data,
      startDate: timeConversionForReactDatePicker(data.startDate),
      endDate: timeConversionForReactDatePicker(data.endDate),
    };
    try {
      // GET request to fetch client data
      const response = await _get(
        endPoints.clients + "/" + clientData.clientId + "/chips-statement",
        null,
        token,
        formatObj,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== responseCode._200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_CHIP_STATEMENT,
        payload: response.data,
      });
      toast.success(response.msg);
    } catch (error) {
      dispatch({
        type: GET_CHIP_STATEMENT,
        payload: [],
      });
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
    setBtnDisable(true);
    setLoading(false);
  };
  return {
    handleFilterChanges,
    chipFilters,
    loading,
    getChipStatement,
    btnDisable,
    chipData
  };
};
