export const endPoints = {
  userlogin: "login",
  logout: "logout",
  clients: "clients",
  changePassword: "user/changepass",
  sports: "sports",
  profitLoss: "user/profit-loss",
  accountStatement: "user/account-statement",
  weeklyReport: "user/weekly-report",
  sportReport: "user/sports-report",
  chipSmry: "user/chip-smry",
  exportAccountStatement: "user/export-file/account-statement",
  exportProfitLossStatement: "user/export-file/profit-loss",
  exportFreeChipStatement: "user/export-file/free-chip",
  exportSettlementStatement: "user/export-file/settlement",
  withdrawal: "/withdrawal",
  supermaster: "/supermaster",
  user: "/user",
  deposit: "/deposit",
  userBets: "/user/bets",
  accStatement: "/account-statement",
  clientChipSmry:"/chip-smry",
  clientSportReport: "/sports-report",
  profile:"/user/profile",
  masterList: "/master-list",
  master:"/master"
};
