import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLogin } from "../../hooks/login-hook";
import { Button, Card, Grid2, Stack, Typography } from "@mui/material";
import { constants } from "../../constants/constants";

import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import { useProfile } from "../../hooks/profile-hook";
import { getFormattedProfileData } from "./constant";

const ChangePasswordSection = () => {
  const token = useSelector((state) => state.auth.token);
  const { handleChangePassword } = useLogin();
  const [password, setpassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [formattedProfileData, setFormattedProfileData] = useState([]);
  const { getProfileData, profileData } = useProfile();

  useEffect(() => {
    getProfileData();
    setFormattedProfileData(getFormattedProfileData(profileData));
  }, []);

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setpassword((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlePassowrdChangeSubmit = () => {
    handleChangePassword(token, password);
    setpassword({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
  };
  return (
    <div className="bg-[#f3f3f3]">
      <div className="bg-white border p-6 rounded-md shadow-lg mx-auto mt-8 lg:mx-auto lg:container">
        <h2 className="text-xl font-semibold border border-gray-300">
          <div className="text-base uppercase p-2.5 font-semibold">
            User Details
          </div>
        </h2>
        <ul className="border border-gray-300 rounded-sm overflow-hidden relative">
          {formattedProfileData.length > 0 &&
            formattedProfileData.map((item, index) => (
              <li
                key={item.title + index}
                className="flex items-center bg-white leading-6 p-2.5 px-5 m-0 border-b border-gray-300"
              >
                {item.icon}
                <span className="m-[10px]">{item.title} :</span> {item.value}
              </li>
            ))}
        </ul>
      </div>

      <div className="bg-white border p-6 rounded-md shadow-lg mx-auto mt-8 lg:mx-auto lg:container">
        <Typography
          variant="h4"
          sx={{
            margin: "5px 0",
            fontSize: "17px",
            textTransform: "uppercase",
            background: "#eee",
            padding: "10px",
            fontWeight: 600,
            border: "1px solid #CC9647",
          }}
        >
          Change Password
        </Typography>

        <Stack item>
          <SearchInputs
            field={constants.textField}
            label={constants.oldPassword}
            value={password.oldPassword}
            onChange={handlePasswordChange}
            name="oldPassword"
          />
        </Stack>

        <Stack item xs={12} sm={12} md={12} lg={12}>
          <SearchInputs
            field={constants.textField}
            label={constants.newPassword}
            value={password.newPassword}
            onChange={handlePasswordChange}
            name="newPassword"
          />
        </Stack>

        <Stack item xs={12} sm={12} md={12} lg={12}>
          <SearchInputs
            field={constants.textField}
            label={constants.confirmNewPassword}
            value={password.confirmNewPassword}
            onChange={handlePasswordChange}
            name="confirmNewPassword"
          />
        </Stack>

        <Grid2 xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="contained"
            onClick={(e) => handlePassowrdChangeSubmit(e)}
            sx={{ marginTop: "20px", bgcolor: "#CC9647" }}
          >
            Update
          </Button>
        </Grid2>
      </div>
    </div>
  );
};

export default ChangePasswordSection;
