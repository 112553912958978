import React from "react";
import "./marquee.css"; 

const Marquee = ({ marqueeText }) => {
  return (
    <div className="marquee-container">
        {/* can be used later for marquee */}
      {/* <div className="marquee-content">
        <p className="text-lg text-gray-800 font-bold">{marqueeText}</p>
      </div> */}
      <marquee>
        {marqueeText}
      </marquee>
    </div>
  );
};

export default Marquee;
