import { useEffect, useRef, useState } from "react";
import { endPoints } from "../services/EndPoints";
import { _get } from "../services/HttpMethods";
import { useDispatch, useSelector } from "react-redux";
import useNavigateHook from "./useNavigateHook";
import { responseCode } from "../constants/responseCode";
import { constants } from "../constants/constants";
import { removeAuthData } from "../utils/common";
import { logoutErrors } from "../constants/ErrorMessage";
import { toast } from "react-toastify";
import { GET_MASTER_SUCCESS } from "../redux/master/actionType";
import { getFormattedSupermasterData } from "../utils/hookUtils";
import { useLocation, useNavigate } from "react-router-dom";

export const useMaster = (locationObj) => {
  const token = useSelector((state) => state.auth.token);
  const [filters, setFilters] = useState({
    username: "",
    sorting: "",
  });
  const [disableBtn, setDisableBtn] = useState(true);
  const { handleNavigate } = useNavigateHook();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { masters } = useSelector((state) => state.master);
  const [formattedMasterData, setFormattedMasterData] = useState([]);
  useEffect(() => {
    console.log(location, "location?.pathname");

    setFormattedMasterData(
      getFormattedSupermasterData(masters, navigate, dispatch, location)
    );
  }, [masters]);

  const handleChangeFilter = (prop, value) => {
    setFilters((prev) => {
      return { ...prev, [prop]: value };
    });
    setDisableBtn(false);
  };

  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current) {
      if (locationObj && locationObj?.role === "3") {
        getMasterList(
          endPoints.supermaster.slice(1) +
            "/" +
            locationObj?.id +
            endPoints.masterList
        );
      } else {
        getMasterList(endPoints.master);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const logoutFunc = () => {
    handleNavigate("/login");
  };

  const getMasterList = async (url, data = {}) => {
    try {
      const response = await _get(url, null, token, data, false, logoutFunc);
      if (!response) {
        return;
      } else if (response.status !== responseCode._200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_MASTER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleSearch = () => {
    getMasterList(
      locationObj && locationObj?.role === "3"
        ? endPoints.supermaster.slice(1) +
            "/" +
            locationObj?.id +
            endPoints.masterList
        : endPoints.master,
      filters
    );
    setDisableBtn(true);
  };

  return {
    filters,
    disableBtn,
    handleChangeFilter,
    handleSearch,
    getMasterList,
    formattedMasterData,
  };
};
