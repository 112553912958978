import React, { useEffect } from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import { Container, Typography } from "@mui/material";
import EnhancedTable from "../../containers/CustomTable/CustomTable";
import { config, weeklyReportTableColumns } from "./constant";
import { getFormattedData } from "../../utils/hookUtils";
import useWeeklyReport from "../../hooks/weeklyReport-hook";

const WeeklyReport = () => {
  const { getWeeklyReport, weeklyReport, dates } = useWeeklyReport();
  const formattedWeeklyData = getFormattedData(
    weeklyReport?.weeklyDetails?.details,
    config,
    false
  );
  const formattedCasinoData = getFormattedData(
    weeklyReport?.casinoDetails?.details,
    config,
    false
  );

  useEffect(() => {
    getWeeklyReport();
  }, []);

  return (
    <Layout
      heading="Weekly report[ProjectzM]"
      marqueeText={commonMarqueeContent}
    >
      <Container disableGutters maxWidth={false}>
        <Typography variant="h4" sx={{ fontSize: "24px", padding: "10px" }}>
          Weekly Report({dates[7]} - {dates[1]})
        </Typography>
        <EnhancedTable
          tableColumns={weeklyReportTableColumns}
          tableData={formattedWeeklyData}
        />
        <Container disableGutters maxWidth={false}>
          <Typography variant="h4" sx={{ fontSize: "24px", padding: "10px" }}>
            Casino Details
          </Typography>
          <EnhancedTable
            tableColumns={weeklyReportTableColumns}
            tableData={formattedCasinoData}
          />
        </Container>
      </Container>
    </Layout>
  );
};

export default WeeklyReport;
