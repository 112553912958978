import React from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import CreateUserForm from "./CreateUserForm";

const CreateUser = () => {
  return (
    <Layout heading="New User" marqueeText={commonMarqueeContent}>
      <CreateUserForm />
    </Layout>
  );
};

export default CreateUser;
