import React, { useEffect, useRef } from "react";
import { commonMarqueeContent } from "../../constants/constants";
import Layout from "../../layout/Layout";
import Loader from "../../components/Loader";
import CustomTable from "../../containers/CustomTable/CustomTable";
import SearchForm from "./SearchForm";
import { useChipStatement } from "../../hooks/chip-statement-hook";
import { chipStatementTableColumns } from "./constants";
import { useLocation } from "react-router-dom";

const ChipStatement = () => {
  const {
    chipFilters,
    handleFilterChanges,
    loading,
    getChipStatement,
    btnDisable,
    chipData,
  } = useChipStatement();
  const location = useLocation();
  const isMounted = useRef(true);

  const handleSearch = () => {
    getChipStatement(location.state.arr, chipFilters);
  };

  useEffect(() => {
    if (isMounted.current) {
      if (location?.state?.arr) {
        handleSearch();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Layout heading="Chip Statement" marqueeText={`${commonMarqueeContent}`}>
      <SearchForm
        chipFilters={chipFilters}
        handleFilterChanges={handleFilterChanges}
        btnDisable={btnDisable}
        handleSearch={handleSearch}
      />
      {loading ? (
        <Loader />
      ) : (
        <CustomTable
          tableColumns={chipStatementTableColumns}
          tableData={chipData}
        />
      )}
    </Layout>
  );
};

export default ChipStatement;
