import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "vrx-v4.firebaseapp.com",
  projectId: "vrx-v4",
  storageBucket: "vrx-v4.appspot.com",
  messagingSenderId: "815290966454",
  appId: "1:815290966454:web:a84b73e9df6625e7c0e117",
  measurementId: "G-4H45X6B46K",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const analytics = getAnalytics(app);
