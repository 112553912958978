import { useEffect, useRef, useState } from "react";
import {
  fourDaysBackDate,
  timeConversionForReactDatePicker,
} from "../utils/common";
import { _get } from "../services/HttpMethods";
import { endPoints } from "../services/EndPoints";
import { useDispatch, useSelector } from "react-redux";
import useNavigateHook from "./useNavigateHook";
import { GET_SPORT_REPORT } from "../redux/sportReport/actionType";
import { toast } from "react-toastify";

export const useSportReport = (location) => {
  const { handleNavigate } = useNavigateHook();
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const token = useSelector((state) => state.auth.token);
  const { sportReport } = useSelector((state) => state.sportReport);
  const [sportReportFilters, setSportReportFilters] = useState({
    startDate: fourDaysBackDate(7),
    endDate: new Date(),
  });
  const [disableBtn, setDisableBtn] = useState(true);

  const logoutFunc = () => {
    handleNavigate("/login");
  };

  const getSportReport = async (formatObj) => {
    try {
      const response = await _get(
        endPoints.sportReport,
        null,
        token,
        formatObj,
        null,
        logoutFunc
      );
      const { status, data, msg } = response;
      if (status !== 200) {
        throw new Error(msg);
      }
      dispatch({
        type: GET_SPORT_REPORT,
        payload: data,
      });
      toast.success(msg);
    } catch (err) {
      toast.error(err.message);
      dispatch({
        type: GET_SPORT_REPORT,
        payload: [],
      });
    }
  };

  const getSportReportForClient = async (formatObj, url) => {
    try {
      const response = await _get(
        url,
        null,
        token,
        formatObj,
        null,
        logoutFunc
      );
      const { status, data, msg } = response;
      if (status !== 200) {
        throw new Error(msg);
      }
      dispatch({
        type: GET_SPORT_REPORT,
        payload: data,
      });
      toast.success(msg);
    } catch (err) {
      toast.error(err.message);
      dispatch({
        type: GET_SPORT_REPORT,
        payload: [],
      });
    }
  };

  const getFormatObj = (sportFilter) => {
    return {
      startDate: timeConversionForReactDatePicker(sportFilter.startDate),
      endDate: timeConversionForReactDatePicker(sportFilter.endDate),
    };
  };

  useEffect(() => {
    if (isMounted.current) {
      if (location?.state?.arr) {
        getSportReportForClient(
          getFormatObj(sportReportFilters),
          location?.state?.arr?.role === "3"
            ? endPoints.supermaster.slice(1) +
                "/" +
                location?.state?.arr?.id +
                endPoints.clientSportReport
            : location?.state?.arr?.role === "2"
            ? endPoints.master.slice(1) +
              "/" +
              location?.state?.arr?.id +
              endPoints.clientSportReport
            : endPoints.clients +
              "/" +
              location?.state?.arr?.clientId +
              endPoints.clientSportReport
        );
      } else getSportReport(getFormatObj(sportReportFilters));
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFilterChanges = (prop, value) => {
    setSportReportFilters((prev) => {
      return { ...prev, [prop]: value };
    });
    setDisableBtn(false);
  };

  const handleSearch = () => {
    if (location?.state?.arr) {
      getSportReportForClient(
        getFormatObj(sportReportFilters),
        location?.state?.arr?.role === "3"
          ? endPoints.supermaster.slice(1) +
              "/" +
              location?.state?.arr?.id +
              endPoints.clientSportReport
          : location?.state?.arr?.role === "2"
          ? endPoints.master.slice(1) +
            "/" +
            location?.state?.arr?.id +
            endPoints.clientSportReport
          : endPoints.clients +
            "/" +
            location?.state?.arr?.clientId +
            endPoints.clientSportReport
      );
    } else getSportReport(getFormatObj(sportReportFilters));
    setDisableBtn(true);
  };
  const handleLifetimeSearch = () => {
    getSportReport({});
    setDisableBtn(true);
  };
  return {
    sportReportFilters,
    handleFilterChanges,
    handleSearch,
    handleLifetimeSearch,
    disableBtn,
    sportReport,
  };
};
