import { useDispatch, useSelector } from "react-redux";
import { endPoints } from "../services/EndPoints";
import { _get } from "../services/HttpMethods";
import useNavigateHook from "./useNavigateHook";
import { responseCode } from "../constants/responseCode";
import { error } from "../constants/errors";
import { constants } from "../constants/constants";
import { removeAuthData } from "../utils/common";
import { toast } from "react-toastify";
import { logoutErrors } from "../constants/ErrorMessage";
import { useEffect, useState } from "react";
import { GET_CLIENT_BETS, GET_USER_BETS } from "../redux/bets/actionType";
import { getFormattedBetData } from "../utils/hookUtils";

export const useBet = () => {
  const dispatch = useDispatch();
  const { clientBets, userBets } = useSelector((state) => state.bets);
  const token = useSelector((state) => state.auth.token);
  const [betFilters, setBetFilters] = useState({
    username: "",
    eventType: "",
    betType: "",
    startDate: "",
    endDate: "",
    minStake: "",
    maxStake: "",
    sorting: "",
  });
  const [bets, setBets] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const { handleNavigate } = useNavigateHook();
  const logoutFunc = () => {
    handleNavigate("/login");
  };

  useEffect(() => {
    setBets(getFormattedBetData(clientBets));
  }, [clientBets]);

  useEffect(() => {
    setBets(getFormattedBetData(userBets));
  }, [userBets]);

  const handleFilterChanges = (prop, value) => {
    setBetFilters((prev) => {
      return { ...prev, [prop]: value };
    });
    setBtnDisable(false);
  };
  const getUnsettledBetsForClients = async (clientData, data = {}) => {
    setLoading(true);
    try {
      // GET request to fetch client data
      const response = await _get(
        endPoints.clients + "/" + clientData.clientId + "/bet",
        null,
        token,
        data,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== responseCode._200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_CLIENT_BETS,
        payload: response.data,
      });
      toast.success(response.msg);
    } catch (error) {
      dispatch({
        type: GET_USER_BETS,
        payload: [],
      });
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
    setBtnDisable(true);
    setLoading(false);
  };
  const getBetsForUser = async (data = {}) => {
    setLoading(true);
    try {
      // GET request to fetch client data
      const response = await _get(
        endPoints.userBets,
        null,
        token,
        data,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== responseCode._200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_USER_BETS,
        payload: response.data,
      });
      toast.success(response.msg);
    } catch (error) {
      dispatch({
        type: GET_USER_BETS,
        payload: [],
      });
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
    setBtnDisable(true);
    setLoading(false);
  };
  return {
    getUnsettledBetsForClients,
    betFilters,
    handleFilterChanges,
    getBetsForUser,
    btnDisable,
    bets,
    loading,
  };
};
