import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fourDaysBackDate,
  timeConversionForReactDatePicker,
} from "../utils/common";
import { _get } from "../services/HttpMethods";
import { endPoints } from "../services/EndPoints";
import useNavigateHook from "./useNavigateHook";
import { toast } from "react-toastify";
import { GET_ACCOUNT_STATEMENT } from "../redux/accountStatement/actionType";
import { getAccountStatementData } from "../utils/hookUtils";

export const useAccountStatement = () => {
  const { handleNavigate } = useNavigateHook();
  const dispatch = useDispatch();
  const { accountStatement } = useSelector((state) => state.accountStatement);
  const [accStatFilters, setAccStatFilters] = useState({
    sports: "",
    type: "",
    startDate: fourDaysBackDate(),
    endDate: new Date(),
  });
  const [formattedAccountStatement, setFormattedAccountStatement] = useState(
    []
  );
  const [disableBtn, setDisableBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    setFormattedAccountStatement(getAccountStatementData(accountStatement));
  }, [accountStatement]);

  const handleFilterChanges = (prop, value) => {
    setAccStatFilters((prev) => {
      return { ...prev, [prop]: value };
    });
    setDisableBtn(false);
  };

  const logoutFunc = () => {
    handleNavigate("/login");
  };

  const getAccountStatementForClient = async (url) => {
    setLoading(true);
    let formatObj = {
      ...accStatFilters,
      startDate: timeConversionForReactDatePicker(accStatFilters.startDate),
      endDate: timeConversionForReactDatePicker(accStatFilters.endDate),
    };
    try {
      const response = await _get(
        url,
        null,
        token,
        formatObj,
        null,
        logoutFunc
      );
      const { status, data, msg } = response;
      if (status !== 200) {
        throw new Error(msg);
      }
      dispatch({
        type: GET_ACCOUNT_STATEMENT,
        payload: data,
      });
      toast.success(msg);
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  };

  const getAccountStatement = async () => {
    setLoading(true);
    let formatObj = {
      ...accStatFilters,
      startDate: timeConversionForReactDatePicker(accStatFilters.startDate),
      endDate: timeConversionForReactDatePicker(accStatFilters.endDate),
    };
    try {
      const response = await _get(
        endPoints.accountStatement,
        null,
        token,
        formatObj,
        null,
        logoutFunc
      );
      const { status, data, msg } = response;
      if (status !== 200) {
        throw new Error(msg);
      }
      dispatch({
        type: GET_ACCOUNT_STATEMENT,
        payload: data,
      });
      toast.success(msg);
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  };

  const handleSearch = async () => {
    getAccountStatement();
    setDisableBtn(true);
  };

  return {
    accStatFilters,
    handleFilterChanges,
    handleSearch,
    formattedAccountStatement,
    disableBtn,
    getAccountStatementForClient,
    getAccountStatement,
    loading,
  };
};
