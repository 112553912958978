import React, { useEffect, useRef } from "react";
import Layout from "../../layout/Layout";
import { _constants } from "./constants";
import { commonMarqueeContent } from "../../constants/constants";
import { useChipSmry } from "../../hooks/chip-smry-hook";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import { endPoints } from "../../services/EndPoints";

const ChipSummary = () => {
  const location = useLocation();
  const {
    formattedChipSmry,
    getChipSmryData,
    getChipSmryDataForClient,
    loading,
  } = useChipSmry(location?.state?.arr);
  const isMounted = useRef(true);
  console.log(location.state.arr, "arrayHere");
  useEffect(() => {
    if (isMounted.current) {
      if (location?.state?.arr) {
        getChipSmryDataForClient(
          location?.state?.arr?.role === "3"
            ? endPoints.supermaster.slice(1) +
                "/" +
                location?.state?.arr?.id +
                endPoints.clientChipSmry
            : location?.state?.arr?.role === "2"
            ? endPoints.master.slice(1) +
              "/" +
              location?.state?.arr?.id +
              endPoints.clientChipSmry
            : endPoints.clients +
              "/" +
              location?.state?.arr?.clientId +
              endPoints.clientChipSmry
        );
      } else {
        getChipSmryData();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, []);
  return (
    <Layout heading="Chip Smry" marqueeText={commonMarqueeContent}>
      <div className="border p-6 rounded-md shadow-lg  mx-auto mt-8">
        <h2 className="text-xl font-semibold  bg-white border  border-gray-300 py-2.5 px-5">
          <div className="m-2.5 text-base uppercase border border-[#cc9647] p-2.5 font-semibold">
            {_constants.ChipSummary}
          </div>
        </h2>
        {loading ? (
          <Loader />
        ) : (
          <ul className=" border border-gray-300 rounded-sm overflow-hidden relative">
            {formattedChipSmry?.length &&
              formattedChipSmry?.map((item, index) => {
                return (
                  <li
                    key={item.title + index}
                    className="flex items-center bg-white leading-6 p-2.5 px-5 m-0 border-b border-gray-300 "
                  >
                    <span className="">{item.title} : </span> {item.value}
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </Layout>
  );
};

export default ChipSummary;
