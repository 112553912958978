import { GET_CLIENT_SUCCESS } from "./actionType";

const initialState = {
  clientAdded: false,
  errorMessage: null,
  clients: [],
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default clientReducer;
