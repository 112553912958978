import React from "react";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import { constants } from "../../constants/constants";
import SearchButton from "../../components/SearchButton";

const SearchForm = ({ chipFilters, handleFilterChanges, btnDisable,handleSearch }) => {
  return (
    <div className="flex items-center gap-[20px] mb-[20px] bg-[white] p-[10px] border border-[#CC9647] flex-wrap">
      <div className="w-[20%]">
        <SearchInputs
          field={constants.dateField}
          label={constants.StartDate}
          value={chipFilters.startDate}
          onChange={(e) => handleFilterChanges("startDate", e)}
          id={"start-date"}
        />
      </div>
      <div className="w-[20%]">
        <SearchInputs
          field={constants.dateField}
          label={constants.EndDate}
          value={chipFilters.endDate}
          onChange={(e) => handleFilterChanges("endDate", e)}
          id={"end-date"}
        />
      </div>

      <SearchButton handleSearch={handleSearch} disableBtn={btnDisable} />
    </div>
  );
};

export default SearchForm;
