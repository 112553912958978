import React, { useState } from "react";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import { constants } from "../../constants/constants";
import { useWithdraw } from "../../hooks/withdraw-hook";
import { Controller, useForm } from "react-hook-form";
import AnimatedInputField from "../../components/AnimatedInputField";
import ErrorField from "../../components/ErrorField";
import { error } from "../../constants/errors";
import { toWords } from "number-to-words";
import { endPoints } from "../../services/EndPoints";

const WithdrawForm = ({ clientData, role }) => {
  const { createWithdraw } = useWithdraw();
  const [outstandingSettlement, setOutstandingSettlement] = useState(
    role === "supermaster" || role === "master"
      ? clientData?.current
      : clientData?.limit?.current
  );
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    // getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      settlementAmount: "",
      Note: "",
    },
  });

  const onSubmit = (data) => {
    const myPromise = createWithdraw(
      data,
      outstandingSettlement,
      role === "supermaster"
        ? endPoints.supermaster.slice(1) +
            "/" +
            clientData?.id +
            endPoints.withdrawal
        : role === "master"
        ? endPoints.master.slice(1) +
          "/" +
          clientData?.id +
          endPoints.withdrawal
        : endPoints.clients + "/" + clientData.clientId + endPoints.withdrawal
    );
    myPromise.then((val) => setOutstandingSettlement(val));
    reset({
      settlementAmount: "",
      Note: "",
    });
  };

  const getRemainingValue = () => {
    if (watch("settlementAmount") === "" || isNaN(watch("settlementAmount")))
      return null;
    return parseInt(outstandingSettlement) - watch("settlementAmount");
  };

  return (
    <div className="m-5 p-5 bg-white">
      <div className="my-1.5 text-lg uppercase bg-gray-200 py-2 font-semibold border border-[#CC9647] pl-[3px]">
        BANK WITHDRAW
      </div>
      <div className="p-[10px]">
        <div className="text-[red]">
          User credit limit : {clientData?.creditLimit || 0}
        </div>
        <form
          className="mt-[10px] w-[50%] flex flex-col gap-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SearchInputs
            field={constants.textField}
            label={"Outstanding Settlement"}
            value={outstandingSettlement}
            onChange={() => {}}
            disable={true}
          />
          <div>
            <Controller
              name="settlementAmount"
              control={control}
              render={({ field }) => (
                <AnimatedInputField
                  label="settlementAmount"
                  type="text"
                  register={register}
                  validation={{
                    required: error.settlementAmountRequired,
                    pattern: {
                      value: /^[0-9]+$/, // Regex to match only numeric values
                      message: "Only numeric values are allowed",
                    },
                  }}
                  errorCheck={errors.settlementAmount}
                  {...field}
                />
              )}
            />
            {errors.settlementAmount && (
              <ErrorField error={errors.settlementAmount.message} />
            )}
          </div>
          {watch("settlementAmount") === "" ||
          isNaN(watch("settlementAmount")) ? null : (
            <div className="relative block min-h-[18px] text-[12px] font-bold text-red-500">
              {toWords(watch("settlementAmount"))}
            </div>
          )}

          <div className="relative min-h-[18px] block text-[12px] text-[rgba(0,0,0,0.54)]">
            Remaining Settlement: {getRemainingValue()}
          </div>
          <div>
            <Controller
              name="Note"
              control={control}
              render={({ field }) => (
                <AnimatedInputField
                  label="Note"
                  type="text"
                  register={register}
                  {...field}
                />
              )}
            />
          </div>
          <div className="w-[10%] rounded-md">
            <button className="p-2 bg-[#CC9647] text-white">
              {constants.Update}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WithdrawForm;
