import React from "react";
import { timeConversionForReactDatePicker } from "../../utils/common";

const Report = ({ sportReport, sportReportFilters }) => {
  return (
    <div className="m-5 bg-white p-5">
      <div className="flex border-b-2 pb-[10px]">
        <h4 className="text-xl pr-[5px] font-bold">Sports Details </h4>{" "}
        <span className="pt-[3px]">
          ({timeConversionForReactDatePicker(sportReportFilters.startDate)} -{" "}
          {timeConversionForReactDatePicker(sportReportFilters.endDate)})
        </span>
      </div>
      {sportReport.map((item, indx) => {
        return (
          <div className="flex w-full pb-[10px] border-b-2">
            <div className="w-[50%] text-lg pr-[5px]">{item.eventType}</div>
            <div
              className="w-[50%] text-lg pr-[5px]"
              style={{
                color: item.amount > 0 ? "green" : "red",
                paddingLeft: item.amount > 0 ? "8px" : null,
              }}
            >
              {item.amount}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Report;
