import { URLs } from "../../routes/URL";

export const reportArr = [
  {
    item: "New Chips Statement",
    navigate: "/chip-statement",
  },
  {
    item: "Chip Smry",
    navigate: URLs.chipSummary,
  },
  {
    item: "Settlement",
    navigate: "/settlement",
  },
  {
    item: "Profit Loss",
    navigate: URLs.profitLoss,
  },
  {
    item: "Settlement Report",
    navigate: URLs.settlementReport,
  },
  {
    item: "Sports Profit Loss",
    navigate: URLs.sportReport,
  },
  {
    item: "Bet History",
    navigate: URLs.betHistory,
  },
];

export const getReportArr = (role) => {
  return [
    {
      item: "Chip Smry",
      navigate: URLs.chipSummary,
    },
    {
      item: "Settlement",
      navigate: "/settlement",
    },
    {
      item: "Settlement Report",
      navigate: URLs.settlementReport,
    },
    {
      item: "Sports Profit Loss",
      navigate: URLs.sportReport,
    },
    {
      item: role === "supermaster" ? "Master List" : "Client List",
      navigate: role === "supermaster" ? URLs.masters : URLs.clients,
    },
  ];
};
