import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import { constants } from "../../constants/constants";
import AnimatedInputField from "../../components/AnimatedInputField";
import ErrorField from "../../components/ErrorField";
import { error } from "../../constants/errors";
import { toWords } from "number-to-words";
import { useDeposit } from "../../hooks/deposit-hook";
import { endPoints } from "../../services/EndPoints";

const DepositForm = ({ clientData, role }) => {
  const [availableChips, setAvailableChips] = useState(
    role === "supermaster" || role === "master"
      ? clientData?.current
      : clientData?.limit?.current
  );
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    // getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      DepositChips: "",
      Note: "",
    },
  });

  const { createDeposit } = useDeposit();

  const onSubmit = (data) => {
    const myPromise = createDeposit(
      data,
      availableChips,
      role === "supermaster"
        ? endPoints.supermaster.slice(1) +
            "/" +
            clientData?.id +
            endPoints.deposit
        : role === "master"
        ? endPoints.master.slice(1) + "/" + clientData?.id + endPoints.deposit
        : endPoints.clients + "/" + clientData.clientId + endPoints.deposit
    );
    myPromise.then((val) => setAvailableChips(val));
    reset({
      DepositChips: "",
      Note: "",
    });
  };

  const getRemainingValue = () => {
    if (watch("DepositChips") === "" || isNaN(watch("DepositChips")))
      return null;
    return parseInt(availableChips) - watch("DepositChips");
  };

  return (
    <div className="m-5 p-5 bg-white">
      <div className="my-1.5 text-lg uppercase bg-gray-200 py-2 font-semibold border border-[#CC9647] pl-[3px]">
        BANK DEPOSIT
      </div>
      <div className="p-[10px]">
        <div className="text-[red]">
          User credit limit : {clientData?.creditLimit || 0}
        </div>
        <form
          className="mt-[10px] w-[50%] flex flex-col gap-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SearchInputs
            field={constants.textField}
            label={"Available Chips"}
            value={availableChips}
            onChange={() => {}}
            disable={true}
          />
          <div>
            <Controller
              name="DepositChips"
              control={control}
              render={({ field }) => (
                <AnimatedInputField
                  label="DepositChips"
                  type="text"
                  register={register}
                  validation={{
                    required: error.DepositChips,
                    pattern: {
                      value: /^[0-9]+$/, // Regex to match only numeric values
                      message: "Only numeric values are allowed",
                    },
                  }}
                  errorCheck={errors.DepositChips}
                  {...field}
                />
              )}
            />
            {errors.DepositChips && (
              <ErrorField error={errors.DepositChips.message} />
            )}
          </div>
          {watch("DepositChips") === "" ||
          isNaN(watch("DepositChips")) ? null : (
            <div className="relative block min-h-[18px] text-[12px] font-bold text-red-500">
              {toWords(watch("DepositChips"))}
            </div>
          )}

          <div className="relative min-h-[18px] block text-[12px] text-[rgba(0,0,0,0.54)]">
            Remaining Settlement: {getRemainingValue()}
          </div>
          <div>
            <Controller
              name="Note"
              control={control}
              render={({ field }) => (
                <AnimatedInputField
                  label="Note"
                  type="text"
                  register={register}
                  {...field}
                />
              )}
            />
          </div>
          <div className="w-[10%] rounded-md">
            <button className="p-2 bg-[#CC9647] text-white">
              {constants.Update}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DepositForm;
