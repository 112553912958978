export const constants = {
  SignIn: "Sign In",
  RememberMe: "Remember Me",
  Login: "Login",
  LOGOUT: "LOGOUT",
  Unauthorized: "Unauthorized",
  Name: "Name",
  Username: "Username",
  SUBMIT: "SUBMIT",
  textField: "textField",
  SearchUsername: "Search Username",
  selectField: "selectField",
  dateField: "dateField",
  Sorting: "Sorting",
  oldPassword: "Old Password",
  newPassword: "New Password",
  confirmNewPassword: "Re-Type Password",
  Sport: "Sport",
  Type: "Type",
  StartDate: "Start Date",
  EndDate: "End Date",
  Update: "UPDATE",
  BetType: "Bet Type",
  MinStake: "Min Stake",
  MaxStake: "Max Stake",
  creditLimit: "Credit Limit",
  creditLimitLink: "Click here for history",
};

export const commonCss = {
  flex_ic_js: "flex items-center justify-center",
};

export const commonMarqueeContent =
  "🏏 𝐆𝐎𝐎𝐃 𝐍𝐄𝐖𝐒 𝐏𝐑𝐄𝐌𝐈𝐔𝐌 𝐌𝐀𝐑𝐊𝐄𝐓 𝐈𝐒 𝐍𝐎𝐖 𝐀𝐕𝐀𝐈𝐋𝐀𝐁𝐋𝐄 (𝟐𝟒*𝟕) 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 (𝐏) , 𝐎𝐔𝐑 𝐄𝐗𝐂𝐋𝐔𝐒𝐈𝐕𝐄 𝐏𝐑𝐄𝐌𝐈𝐔𝐌 𝐌𝐀𝐑𝐊𝐄𝐓 𝐅𝐎𝐑 (𝐒𝐑𝐋) 𝐈𝐒 𝐍𝐎𝐖 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 , 𝐃𝐑𝐄𝐀𝐌 𝐁𝐈𝐆 𝐖𝐈𝐍 𝐁𝐈𝐆 💰";
