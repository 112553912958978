import axios from "axios";
import { toast } from "react-toastify";
import { removeAuthData } from "../utils/common";
const headers = {
  "Content-Type": "application/json",
};

const checkoutLogout = (logoutFunc) => {
  removeAuthData();
  logoutFunc();
};

export const _get = async (
  endpoint,
  id = null,
  token = null,
  data = null,
  idUrlChange = false,
  logoutFunc
) => {
  try {
    // Adjust the URL based on whether id is provided
    const url = idUrlChange
      ? `${process.env.REACT_APP_URL_DEV}/${idUrlChange}/${endpoint}`
      : id
      ? `${process.env.REACT_APP_URL_DEV}/${endpoint}/${id}`
      : `${process.env.REACT_APP_URL_DEV}/${endpoint}`;
    const response =
      token && data
        ? await axios.get(url, {
            headers: { authorizationToken: `Bearer ${token}` },
            params: data,
          })
        : token
        ? await axios.get(url, {
            headers: { authorizationToken: `Bearer ${token}` },
          })
        : await axios.get(url);
    return response.data;
  } catch (error) {
    checkoutLogout(logoutFunc);
    toast.error(error.message);
  }
};
export const _getRdy = async (endpoint, token = null, data = null) => {
  try {
    const url = `${process.env.REACT_APP_RDYPANEL_URL}/${endpoint}`;
    const response =
      token && data
        ? await axios.get(url, {
            headers: { authorizationToken: `Bearer ${token}` },
            params: data,
          })
        : token
        ? await axios.get(url, {
            headers: { authorizationToken: `Bearer ${token}` },
          })
        : await axios.get(url);
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const _post = async (
  endpoint,
  data,
  token = null,
  resetHeaders = false,
  appUrl = false,
  logoutFunc = () => {}
) => {
  try {
    const config = {
      headers: { ...headers }, // Create a copy of the headers
    };

    if (resetHeaders) {
      config.headers = { "Content-Type": "application/json" };
    }

    if (token) {
      config.headers["authorizationToken"] = `Bearer ${token}`;
    }

    const response = await axios.post(
      `${
        appUrl
          ? process.env.REACT_APP_URL_DEV
          : process.env.REACT_APP_USER_PERMISSION_URL
      }/${endpoint}`,
      data,
      config
    );
    return response;
  } catch (error) {
    checkoutLogout(logoutFunc);
    toast.error(error.message);
  }
};

export const _put = async (
  endpoint,
  data,
  token = null,
  resetHeaders = false,
  appUrl = false,
  logoutFunc = () => {}
) => {
  try {
    const config = {
      headers: { ...headers }, // Create a copy of the headers
    };

    if (resetHeaders) {
      config.headers = { "Content-Type": "application/json" };
    }

    if (token) {
      config.headers["authorizationToken"] = `Bearer ${token}`;
    }

    const response = await axios.put(
      `${
        appUrl
          ? process.env.REACT_APP_URL_DEV
          : process.env.REACT_APP_USER_PERMISSION_URL
      }/${endpoint}`,
      data,
      config
    );
    return response;
  } catch (error) {
    checkoutLogout(logoutFunc);
    toast.error(error.message);
  }
};
