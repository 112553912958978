import React from "react";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import { constants } from "../../constants/constants";
import SearchButton from "../../components/SearchButton";

const SearchForm = ({
  settlementFilters,
  handleFilterChanges,
  handleSearch,
  handleExport,
  disableBtn,
}) => {
  return (
    <div className="flex items-center gap-[20px] mb-[20px] bg-[white] p-[10px] border border-[#CC9647]">
      <div className="w-[20%]">
        <SearchInputs
          field={constants.dateField}
          label={constants.StartDate}
          value={settlementFilters.startDate}
          onChange={(e) => handleFilterChanges("startDate", e)}
          id={"start-date"}
        />
      </div>
      <div className="w-[20%]">
        <SearchInputs
          field={constants.dateField}
          label={constants.EndDate}
          value={settlementFilters.endDate}
          onChange={(e) => handleFilterChanges("endDate", e)}
          id={"end-date"}
        />
      </div>
      <SearchButton handleSearch={handleExport} btnTitle="EXPORT" />
      <SearchButton
        handleSearch={handleSearch}
        disableBtn={disableBtn}
      />
    </div>
  );
};

export default SearchForm;
