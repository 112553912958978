import React from "react";
import EventDetailsHeading from "./EventDetailsHeading";
import EventOddsContainer from "./EventOddsContainer";
import EventDetailsFooter from "./EventDetailsFooter";

const EventDetails = ({ itemDetails, titleName }) => {
  return (
    ((titleName === "FANCY" && itemDetails.length > 0) ||
      (titleName !== "FANCY" && itemDetails[0]?.loop.length > 0)) && (
      <div className="mt-[5px] border border-black">
        <EventDetailsHeading titleName={titleName} />
        {!(titleName === "FANCY")
          ? itemDetails[0]?.loop?.map((item, indx) => {
              return (
                <EventOddsContainer
                  runner={item?.full_name}
                  back={item?.back}
                  backSize={"-"}
                  lay={item?.lay}
                  laySize={"-"}
                  titleName={titleName}
                />
              );
            })
          : itemDetails?.map((item, indx) => {
              return (
                <EventOddsContainer
                  runner={item?.headname}
                  back={item?.SessInptNo}
                  backSize={item?.NoValume}
                  lay={item?.SessInptYes}
                  laySize={item?.YesValume}
                  titleName={titleName}
                  minVal={parseInt(item?.min_limit, 10)}
                  maxVal={parseInt(item?.max_limit, 10)}
                  displayMessage={item?.DisplayMsg}
                />
              );
            })}
        <EventDetailsFooter />
      </div>
    )
  );
};

export default EventDetails;
