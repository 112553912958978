import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { color } from "../../color/color";
import { GiConsoleController } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import TV from "../../assets/tv.png";
import {
  getCombinedMatchOddsData,
  getFormattedInPlayData,
} from "../../utils/common";
import { useSocket } from "../../SocketContext";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles("dark", {}),
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  padding: "0px !important",
}));

const CommonSportsDetails = ({ matchData, view }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [matches, setMatches] = useState([]);
  const [odds, setOdds] = useState([]);
  const { socket, turnOffEmit, disconnectAll, reconnectSocket } = useSocket();
  const avoidRerender = useRef(false);
  const socketTimeout = useRef(null);

  useEffect(() => {
    if (socket) {
      if (socketTimeout.current) {
        clearTimeout(socketTimeout.current);
      }

      if (!avoidRerender.current) {
        avoidRerender.current = true;
        socket.emit("odds", 4);
        socket.on("odds", (data) => setOdds(data));
      }
      return () => (avoidRerender.current = true);
      // return () => {
      //   socket.disconnect()
      //   socket.off()
      //   socket.disconnect(true)
      // }
    }
  }, [socket]);

  useEffect(() => {
    if (
      matches &&
      matches?.length &&
      odds &&
      odds?.data &&
      Object.keys(odds?.data)?.length
    ) {
      setMatches(getCombinedMatchOddsData(matches, odds));
    }
  }, [odds]);

  useEffect(() => {
    const sportsData =
      matchData.filter((item) => item.sportsName === view) || [];
    setMatches(
      view === "HOME"
        ? matchData
        : view === "IN-PLAY"
        ? getFormattedInPlayData(matchData)
        : sportsData
    );
  }, [view, matchData]);
  return (
    <div>
      <div>
        {matches.map((item) => (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            sx={{
              background: color._F3F3F3,
              border: "none",
              marginBottom: "5px",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                backgroundColor: color._333,
                borderTopRightRadius: "0.5rem",
                borderTopLeftRadius: "0.5rem",
                minHeight: "30px",
                marginLeft: "0px",
                color: color._FFFFFF,
                "&.Mui-expanded": {
                  backgroundColor: color._333,
                  color: color._FFFFFF,
                },
                ".MuiAccordionSummary-content": {
                  justifyContent: "space-between",
                },
              }}
            >
              <Typography sx={{ textTransform: "uppercase" }}>
                {item?.sportsName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                "&.MuiAccordionDetails-root": {
                  padding: "10px",
                },
              }}
            >
              <Typography>
                <table className="event-list w-[100%]">
                  <tbody>
                    <tr className="hide" />
                    {item?.matches?.map((match) => (
                      <tr className="bg-white border-b border-black/12">
                        <td className="pt-[5px] pb-[5px] align-middle p-[4px] pb-[10px]">
                          <div
                            onClick={() =>
                              navigate(`/event/${match.eventId}`, {
                                state: {
                                  matchData: match,
                                },
                              })
                            }
                            className="w-full block h-full text-black !text-black text-xs cursor-pointer"
                          >
                            <div className="font-semibold flex">
                              {match.eventTypeId === "44" && (
                                <GiConsoleController />
                              )}
                              <span className="ml-[5px]">
                                {" "}
                                {match?.teamName}
                              </span>
                              <em className="text-[10px] font-normal ml-[10px]">
                                {match.stime}
                              </em>
                            </div>
                            <div
                              className="bg-none mb-0 align-middle flex-wrap border-b border-gray-200
bg-white p-0 pl-1.5 items-center inline-flex float-right"
                            >
                              <button
                                type="button"
                                className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#BEE8FA]"
                              >
                                <span className="block">{match.uback1}</span>
                                <em className="font-[10px]">{match.lback1}</em>
                              </button>
                              <button
                                type="button"
                                className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#FEC0C3]"
                              >
                                <span className="block">{match.ulay1}</span>
                                <em className="font-[10px]">{match.llay1}</em>
                              </button>

                              <button
                                type="button"
                                className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#BEE8FA]"
                              >
                                <span className="block">{match.uback2}</span>
                                <em className="font-[10px]">{match.lback2}</em>
                              </button>
                              <button
                                type="button"
                                className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#FEC0C3]"
                              >
                                <span className="block">{match.ulay2}</span>
                                <em className="font-[10px]">{match.llay2}</em>
                              </button>

                              <button
                                type="button"
                                className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#BEE8FA]"
                              >
                                <span className="block">{match.uback3}</span>
                                <em className="font-[10px]">{match.lback3}</em>
                              </button>
                              <button
                                type="button"
                                className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#FEC0C3]"
                              >
                                <span className="block">{match.ulay3}</span>
                                <em className="font-[10px]">{match.llay3}</em>
                              </button>
                            </div>
                            <br />
                            <div className="list-extra-btns relative flex flex-col items-start">
                              <div className="flex items-center">
                                <small>({match.teamName})</small>
                                {(match.uback1 !== "-" ||
                                  match.ulay1 !== "-" ||
                                  match.uback2 !== "-" ||
                                  match.ulay2 !== "-" ||
                                  match.uback3 !== "-" ||
                                  match.ulay3 !== "-") && (
                                  <>
                                    <span className="flex items-center justify-center font-extrabold text-white text-[10px] h-[18px] w-[18px] rounded-full ml-2 animate-blink">
                                      F
                                    </span>
                                    <span className="flex items-center justify-center font-extrabold text-white text-[10px] h-[18px] w-[18px] rounded-full ml-2 animate-blink">
                                      B
                                    </span>
                                  </>
                                )}

                                {match.isTV !== "0" && (
                                  <img
                                    src={TV}
                                    alt="tv"
                                    width="15px"
                                    height="20px"
                                    className="ml-2"
                                  />
                                )}

                                {match.isPlay === "1" && (
                                  <i className="bg-green-500 pl-1 align-middle top-0 h-2.5 leading-5 border-none shadow-none rounded-full relative w-2.5 inline-flex ml-2.5 animate-pulse"></i>
                                )}
                              </div>
                              {/* <span className="absolute top-[25px] font-semibold text-red-500 pl-[5px] left-0">
                            0
                          </span> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default CommonSportsDetails;
