import React from "react";
import { commonMarqueeContent } from "../../constants/constants";
import Layout from "../../layout/Layout";
import { useLocation } from "react-router-dom";
import EventContent from "./EventContent";

const Event = () => {
  const location = useLocation();
  return (
    <Layout
      heading={location?.state?.matchData?.teamName}
      marqueeText={`${commonMarqueeContent}`}
    >
      <EventContent matchData={location?.state?.matchData} />
    </Layout>
  );
};

export default Event;
