import React from "react";
import { useLocation } from "react-router-dom";
import { commonMarqueeContent } from "../../constants/constants";
import Layout from "../../layout/Layout";
import SearchClients from "../Clients/SearchClients";
import { useSuperMaster } from "../../hooks/supermaster-hook";
import CustomTable from "../../containers/CustomTable/CustomTable";
import { supermasterTableColumns } from "./constants";

const Supermaster = () => {
  const name = localStorage.getItem("userName");
  const location = useLocation();
  const {
    filters,
    handleChangeFilter,
    handleSearch,
    disableBtn,
    formattedSupermasterData,
  } = useSuperMaster(location);
  return (
    <Layout heading={name} marqueeText={`${commonMarqueeContent}`}>
      <SearchClients
        filters={filters}
        handleChangeFilter={handleChangeFilter}
        handleSearch={handleSearch}
        disableBtn={disableBtn}
      />
      <CustomTable
        tableColumns={supermasterTableColumns}
        tableData={formattedSupermasterData}
      />
    </Layout>
  );
};

export default Supermaster;
