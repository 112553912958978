import React from "react";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import { constants } from "../../constants/constants";
import { betTypeArr, sportsArr, stakeSelect } from "./constants";
import SearchButton from "../../components/SearchButton";

const SearchForm = ({
  betFilters,
  handleFilterChanges,
  getBetsData,
  btnDisable,
}) => {
  return (
    <div className="flex items-center gap-[20px] mb-[20px] bg-[white] p-[10px] border border-[#CC9647] flex-wrap">
      <div className="w-[10%]">
        <SearchInputs
          field={constants.textField}
          label={constants.Username}
          value={betFilters.username}
          onChange={(e) => handleFilterChanges("username", e.target.value)}
        />
      </div>
      <div className="w-[10%]">
        <SearchInputs
          field={constants.selectField}
          label={constants.Sport}
          value={betFilters.eventType}
          onChange={(e) => handleFilterChanges("eventType", e.target.value)}
          optionArr={sportsArr}
          id={"type-select"}
        />
      </div>
      <div className="w-[20%]">
        <SearchInputs
          field={constants.selectField}
          label={constants.BetType}
          value={betFilters.betType}
          onChange={(e) => handleFilterChanges("betType", e.target.value)}
          optionArr={betTypeArr}
          id={"type-select"}
        />
      </div>
      <div className="w-[20%]">
        <SearchInputs
          field={constants.dateField}
          label={constants.StartDate}
          value={betFilters.startDate}
          onChange={(e) => handleFilterChanges("startDate", e)}
          id={"start-date"}
        />
      </div>
      <div className="w-[20%]">
        <SearchInputs
          field={constants.dateField}
          label={constants.EndDate}
          value={betFilters.endDate}
          onChange={(e) => handleFilterChanges("endDate", e)}
          id={"end-date"}
        />
      </div>
      <div className="w-[10%]">
        <SearchInputs
          field={constants.textField}
          label={constants.MinStake}
          value={betFilters.minStake}
          onChange={(e) => handleFilterChanges("minStake", e.target.value)}
        />
      </div>
      <div className="w-[10%]">
        <SearchInputs
          field={constants.textField}
          label={constants.MaxStake}
          value={betFilters.maxStake}
          onChange={(e) => handleFilterChanges("maxStake", e.target.value)}
        />
      </div>
      <div className="w-[10%]">
        <SearchInputs
          field={constants.selectField}
          label={""}
          value={betFilters.stake}
          onChange={(e) => handleFilterChanges("sorting", e.target.value)}
          optionArr={stakeSelect}
          id={"type-select"}
        />
      </div>
      <SearchButton
        handleSearch={() => getBetsData()}
        disableBtn={btnDisable}
      />
      <SearchButton
        btnTitle="SELECT BETS"
        handleSearch={() => {}}
        disableBtn={false}
      />
    </div>
  );
};

export default SearchForm;
