import React from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import SearchForm from "./SearchForm";
import { useSettlement } from "../../hooks/settlement-hook";
import CustomTable from "../../containers/CustomTable/CustomTable";
import { settlementTableColumns } from "./constants";

const SettlementReport = () => {
  const {
    settlementFilters,
    handleFilterChanges,
    handleSearch,
    handleExport,
    disableBtn,
  } = useSettlement();
  return (
    <Layout
      heading={`SETTLEMENT REPORT`}
      marqueeText={`${commonMarqueeContent}`}
    >
      <SearchForm
        settlementFilters={settlementFilters}
        handleFilterChanges={handleFilterChanges}
        handleSearch={handleSearch}
        handleExport={handleExport}
        disableBtn={disableBtn}
      />
      <CustomTable tableColumns={settlementTableColumns} tableData={[]} />
    </Layout>
  );
};

export default SettlementReport;
