import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchSports } from "../redux/sports/action";
import AllSports from "../containers/AllSports/AllSports";
import Tabs from "./Tabs";
import useDynamoDb from "../hooks/dynamoDbHook";
import useFirebase from "../hooks/firebaseHook";
import { getAllMatches } from "../utils/common";

const Sports = () => {
  const avoidRerender = useRef(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [view, setView] = useState("HOME");
  const sportsData = useSelector((state) => state.sports.sportsData);
  const [sports, setSports] = useState([]);
  const { data, loading } = useDynamoDb({ tableName: "rdy_matches" });
  // const { data, loading } = useFirebase({ tableName: 'rdy_matches' })

  useEffect(() => {
    if (!avoidRerender.current) {
      !sportsData && dispatch(fetchSports(token));
    }
    return () => (avoidRerender.current = true);
  }, []);

  useEffect(() => {
    const data =
      sportsData?.length &&
      sportsData?.sort((a, b) => {
        return a?.sortOrder - b?.sortOrder;
      });
    setSports(data);
  }, [sportsData]);

  const onTabChange = (item) => {
    setView(item.name);
  };
  const matchData = getAllMatches(data);

  return (
    <div>
      <div className="flex">
        <div className="bg-[#ffffff] flex-[0.1]">
          <Tabs onTabChange={onTabChange} sports={sports} view={view} />
        </div>
        <div className="flex-[0.9] ml-[10px] bg-[#ffffff] w-[100%]">
          <AllSports view={view} matchData={matchData} />
        </div>
      </div>
    </div>
  );
};

export default Sports;
