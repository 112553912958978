import { useSelector } from "react-redux";
import { _post } from "../services/HttpMethods";
import useNavigateHook from "./useNavigateHook";
import { responseCode } from "../constants/responseCode";
import { toast } from "react-toastify";
import { constants } from "../constants/constants";
import { removeAuthData } from "../utils/common";
import { logoutErrors } from "../constants/ErrorMessage";

export const useDeposit = () => {
  const token = useSelector((state) => state.auth.token);
  const { handleNavigate } = useNavigateHook();
  const logoutFunc = () => {
    handleNavigate("/login");
  };
  const createDeposit = async (data, availableChips, url) => {
    try {
      // GET request to fetch client data
      const response = await _post(
        url,
        {
          amount: data.DepositChips,
          note: data.Note,
        },
        token,
        false,
        true,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.data.status !== responseCode._200) {
        throw new Error(response.data.msg);
      }
      toast.success(response.data.msg);
      return availableChips - data.DepositChips;
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
        return availableChips;
      }
    }
  };
  return {
    createDeposit,
  };
};
