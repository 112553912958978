import React from "react";
import { FaStar } from "react-icons/fa";
import Event_B from "../../assets/B.png";
import Stats from "../../assets/stats.png";
const EventOddsContainer = ({
  runner = "India",
  back = "1.93",
  backSize = "315",
  lay = "1.94",
  laySize = "535",
  titleName = "Match Odds",
  minVal = "100",
  maxVal = "25000",
  displayMessage = "",
}) => {
  return (
    <div className="flex border-b border-gray-300">
      <div className="w-[65%]">
        {titleName === "FANCY" ? (
          <div className="flex items-center w-[40%]">
            <div className="w-[10%]">
              <FaStar />
            </div>
            <div className="w-[10%]">
              <img src={Event_B} alt="event_B" width="12px" height="12px" />
            </div>

            <div className="font-bold text-[11px] text-black capitalize align-middle pl-1.5 pt-1">
              {runner}
            </div>
          </div>
        ) : (
          <div className="font-bold text-[11px] text-black capitalize align-middle pl-1.5 pt-1">
            {runner}
          </div>
        )}

        <div className="font-bold text-[11px] text-black capitalize align-middle pl-1.5 pt-1">
          -
        </div>
      </div>
      <div className="flex items-center text-center w-[35%]">
        <div className="w-[40%]">
          {titleName === "FANCY" && (
            <>
              <div className="flex items-center justify-around">
                <img src={Stats} alt="event_B" width="18px" height="18px" />
              </div>
              <div className="text-[9px] font-semibold p-0 text-right mr-[5px]">
                <span>Min:</span>
                <span>{minVal}</span>
                <span className="ml-[2px]">Max:</span>
                <span>{maxVal}</span>
              </div>
            </>
          )}
        </div>
        <div className="w-[60%]">
          {displayMessage === "SUSPENDED" ? (
            <div className=" bg-black bg-opacity-50 flex items-center justify-center">
              <span className="text-white text-xl font-bold">
                {displayMessage}
              </span>
            </div>
          ) : (
            <div className="w-full flex items-center text-center">
              <div className="bg-[#9ae0ff] mx-1 w-[50%]">
                <div className="text-[14px] font-bold">{back}</div>
                <div className="text-[11px] font-semibold tracking-normal">
                  {backSize}
                </div>
              </div>
              <div className="bg-[#fec0c3] w-[50%]">
                <div className="text-[14px] font-bold">{lay}</div>
                <div className="text-[11px] font-semibold tracking-normal">
                  {laySize}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventOddsContainer;
