export const sportsArr = [
  { value: "All", label: "All" },
  { value: "Cricket", label: "Cricket" },
  { value: "Football", label: "Football" },
  { value: "Tennis", label: "Tennis" },
  { value: "Horse Racing", label: "Horse Racing" },
  { value: "Greyhound Racing", label: "Greyhound Racing" },
  { value: "Kabaddi", label: "Kabaddi" },
  { value: "Binary", label: "Binary" },
  { value: "Sports Book", label: "Sports Book" },
  { value: "Politics", label: "Politics" },
  { value: "Live Casino", label: "Live Casino" },
  { value: "Int Casino", label: "Int Casino" },
  { value: "Casino Vivo", label: "Casino Vivo" },
  { value: "iCasino", label: "iCasino" },
];

export const typeArr = [
  { value: "All", label: "All" },
  { value: "Profit/Loss", label: "Profit/Loss" },
  { value: "Settlement", label: "Settlement" },
  { value: "Free Chip", label: "Free Chip" },
];

export const accountStatementTableColumns = [
  {
    id: "no",
    numeric: true,
    disablePadding: true,
    label: "NO",
    // width: "20%",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "DATE",
    // width: "1%",
  },
  {
    id: "desc",
    numeric: false,
    disablePadding: true,
    label: "DESC",
    // width: "1%",
  },
  {
    id: "commIn",
    numeric: true,
    disablePadding: true,
    label: "COMM IN",
    // width: "1%",
  },
  {
    id: "commOut",
    numeric: true,
    disablePadding: true,
    label: "COMM OUT",
    // width: "1%",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: true,
    label: "AMOUNT",
    // width: "15%",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: true,
    label: "TOTAL",
    // width: "1%",
  },
  {
    id: "dc",
    numeric: false,
    disablePadding: true,
    label: "D/C",
    // width: "1%",
  },
  {
    id: "balance",
    numeric: true,
    disablePadding: true,
    label: "BALANCE",
    // width: "40%",
  },
  {
    id: "details",
    numeric: false,
    disablePadding: true,
    label: "DETAILS",
    // width: "40%",
  },
];
