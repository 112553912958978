import { useEffect, useRef, useState } from "react";
import { _get } from "../services/HttpMethods";
import { endPoints } from "../services/EndPoints";
import { useDispatch, useSelector } from "react-redux";
import useNavigateHook from "./useNavigateHook";
import { responseCode } from "../constants/responseCode";
import { constants } from "../constants/constants";
import { removeAuthData } from "../utils/common";
import { toast } from "react-toastify";
import { logoutErrors } from "../constants/ErrorMessage";
import { GET_SUPERMASTER_DATA } from "../redux/supermaster/actionType";
import { getFormattedSupermasterData } from "../utils/hookUtils";
import { useNavigate } from "react-router-dom";

export const useSuperMaster = (location) => {
  const token = useSelector((state) => state.auth.token);
  const { supermaster } = useSelector((state) => state.supermaster);
  const [filters, setFilters] = useState({
    username: "",
    sorting: "",
  });
  const [disableBtn, setDisableBtn] = useState(true);
  const [formattedSupermasterData, setFormattedSupermasterData] = useState([]);
  const { handleNavigate } = useNavigateHook();
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChangeFilter = (prop, value) => {
    setFilters((prev) => {
      return { ...prev, [prop]: value };
    });
    setDisableBtn(false);
  };

  const logoutFunc = () => {
    handleNavigate("/login");
  };

  useEffect(() => {
    setFormattedSupermasterData(
      getFormattedSupermasterData(
        supermaster,
        navigate,
        dispatch,
        location?.pathname
      )
    );
  }, [supermaster]);

  useEffect(() => {
    if (isMounted.current) {
      getSupermasters(null, location?.state);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getSupermasters = async (params = null, data = {}) => {
    try {
      // GET request to fetch client data
      const response = await _get(
        endPoints.supermaster,
        params,
        token,
        data,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== responseCode._200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_SUPERMASTER_DATA,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleSearch = () => {
    getSupermasters(null, filters);
    setDisableBtn(true);
  };
  return {
    filters,
    handleChangeFilter,
    disableBtn,
    handleSearch,
    getSupermasters,
    formattedSupermasterData,
  };
};
