import React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { color } from "../../color/color";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles("dark", {}),
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  padding: "0px !important",
}));

const Politics = () => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      {" "}
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            background: color._F3F3F3,
            border: "none",
            marginBottom: "5px",
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            sx={{
              backgroundColor: color._333,
              borderTopRightRadius: "0.5rem",
              borderTopLeftRadius: "0.5rem",
              minHeight: "30px",
              marginLeft: "0px",
              color: color._FFFFFF,
              "&.Mui-expanded": {
                backgroundColor: color._333,
                color: color._FFFFFF,
              },
              ".MuiAccordionSummary-content": {
                justifyContent: "space-between",
              },
            }}
          >
            <Typography sx={{ textTransform: "uppercase" }}>
              Politics
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              "&.MuiAccordionDetails-root": {
                padding: "10px",
              },
            }}
          >
            <Typography>
              <table className="event-list w-[100%]">
                <tbody>
                  <tr className="hide" />
                  <tr className="bg-white border-b border-black/12">
                    <td className="pt-[5px] pb-[5px] align-middle p-[4px] pb-[10px]">
                      <a
                        href="https://admin.reddybook.club/backend/2651279/event/28327605"
                        className="w-full block h-full text-black !text-black text-xs"
                      >
                        <span className="font-semibold">
                          Caribbean Premier League
                          <em className="text-[10px] font-normal ml-[10px]">
                            (29/08/2024 04:30:00 AM)
                          </em>
                        </span>
                        <div
                          className="bg-none mb-0 align-middle flex-wrap border-b border-gray-200
    bg-white p-0 pl-1.5 items-center inline-flex float-right"
                        >
                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#BEE8FA]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>
                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#FEC0C3]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>

                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#BEE8FA]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>
                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#FEC0C3]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>

                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#BEE8FA]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>
                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#FEC0C3]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>
                        </div>
                        <br />
                        <div
                          className="list-extra-btns"
                          style={{
                            display: "flex !important",
                            alignItems: "center !important",
                            position: "relative",
                          }}
                        >
                          <small>(Caribbean Premier League)</small>&nbsp;
                          <span className="absolute -bottom-[15px] font-semibold text-red-500 pl-[5px] left-0">
                            0
                          </span>
                          <span className="font-extrabold text-[10px] h-[18px] w-[18px]">
                            B
                          </span>
                          <i class="bg-green-500 pl-1 align-middle top-0 h-2.5 leading-5 border-none shadow-none rounded-full relative w-2.5 inline-flex ml-2.5 animate-blink-inplay"></i>
                        </div>
                      </a>
                    </td>
                  </tr>
                  <tr className="bg-white border-b border-black/12">
                    <td className="pt-[5px] pb-[5px] align-middle p-[4px] pb-[10px]">
                      <a
                        href="https://admin.reddybook.club/backend/2651279/event/28327605"
                        className="w-full block h-full text-black !text-black text-xs"
                      >
                        <span className="font-semibold">
                          Caribbean Premier League
                          <em className="text-[10px] font-normal ml-[10px]">
                            (29/08/2024 04:30:00 AM)
                          </em>
                        </span>
                        <div
                          className="bg-none mb-0 align-middle flex-wrap border-b border-gray-200
    bg-white p-0 pl-1.5 items-center inline-flex float-right"
                        >
                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#BEE8FA]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>
                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#FEC0C3]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>

                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#BEE8FA]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>
                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#FEC0C3]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>

                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#BEE8FA]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>
                          <button
                            type="button"
                            className="w-[62px] h-[42px] m-0 mx-[1px] border-none bg-[#FEC0C3]"
                          >
                            <span className="block">3.5</span>
                            <em className="font-[10px]">9.67</em>
                          </button>
                        </div>
                        <br />
                        <div
                          className="list-extra-btns"
                          style={{
                            display: "flex !important",
                            alignItems: "center !important",
                            position: "relative",
                          }}
                        >
                          <small>(Caribbean Premier League)</small>&nbsp;
                          <span className="absolute -bottom-[15px] font-semibold text-red-500 pl-[5px] left-0">
                            0
                          </span>
                          <span className="font-extrabold text-[10px] h-[18px] w-[18px]">
                            B
                          </span>
                          <i class="bg-green-500 pl-1 align-middle top-0 h-2.5 leading-5 border-none shadow-none rounded-full relative w-2.5 inline-flex ml-2.5 animate-blink-inplay"></i>
                        </div>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Politics;
