import React from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import CreateClientForm from "./CreateClientForm";

const CreateClient = () => {
  return (
    <Layout heading="New Client" marqueeText={commonMarqueeContent}>
      <CreateClientForm/>
    </Layout>
  );
};

export default CreateClient;
