import { URLs } from "../../routes/URL";

export const marketOptions = [
  {
    name: "Market Analysis",
    color: "#CC9647",
    bold: true,
  },
  {
    name: "Multi Market",
    color: "#CC9647",
    bold: true,
  },
  {
    name: "Unsettled Bets",
    color: "#CC9647",
    bold: true,
    url: URLs.betHistory,
  },
  {
    name: "Casino1 Pending Bets",
    color: "#CC9647",
    bold: true,
  },
  {
    name: "Int Casino History",
    color: "#CC9647",
    bold: false,
  },
];
export const clientListOptions = [
  {
    name: "Client",
    color: "#CC9647",
    bold: true,
    spanCheck: true,
    url: "/clients",
    params: {},
  },
  {
    name: "Blocked Client",
    color: "#CC9647",
    bold: true,
    spanCheck: true,
    url: `/clients`,
    params: { blocked: true },
  },
];

export const superMasterListOptions = [
  {
    name: "Supermasters",
    color: "#CC9647",
    bold: true,
    spanCheck: true,
    url: "/supermasters",
    params: {},
  },
  {
    name: "Blocked Supermasters",
    color: "#CC9647",
    bold: true,
    spanCheck: true,
    url: `/supermasters`,
    params: { blocked: true },
  },
];

export const masterListOptions = [
  {
    name: "Masters",
    color: "#CC9647",
    bold: true,
    spanCheck: true,
    url: "/masters",
    params: {},
  },
  {
    name: "Blocked Masters",
    color: "#CC9647",
    bold: true,
    spanCheck: true,
    url: `/masters`,
    params: { blocked: true },
  },
];

export const projectListOptions = [
  {
    name: "Profit Loss",
    color: "#CC9647",
    bold: false,
    url: URLs.profitLoss,
  },
  {
    name: "A/C Statement",
    color: "#CC9647",
    bold: false,
    url: URLs.accountStatement,
  },
  {
    name: "Top Clients",
    color: "#CC9647",
    bold: false,
  },
  {
    name: "Sport Report",
    color: "#CC9647",
    bold: false,
    url: URLs.sportReport,
  },
  {
    name: "Weekly Report",
    color: "#CC9647",
    bold: false,
    url: URLs.weeklyReport,
  },
  {
    name: "Settlement Report",
    color: "#CC9647",
    bold: false,
    url: URLs.settlementReport,
  },
  {
    name: "Chip Smry",
    color: "#CC9647",
    bold: false,
    url: URLs.chipSummary,
  },
  {
    name: "Balance Sheet",
    color: "#CC9647",
    bold: false,
  },
  {
    name: "Settlement",
    color: "#CC9647",
    bold: false,
  },
  {
    name: "Export",
    color: "#CC9647",
    bold: false,
    url: URLs.export,
  },
  {
    name: "Profile",
    color: "#CC9647",
    bold: false,
    url: URLs.profile,
  },
  {
    name: "Change Password",
    color: "#CC9647",
    bold: false,
    url: URLs.profile,
  },
];
