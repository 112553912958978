import { useState } from "react";
import { fourDaysBackDate } from "../utils/common";

export const useSettlement = () => {
  const [settlementFilters, setSettlementFilters] = useState({
    startDate: fourDaysBackDate(7),
    endDate: new Date(),
  });
  const [disableBtn, setDisableBtn] = useState(true);

  const handleFilterChanges = (prop, value) => {
    setSettlementFilters((prev) => {
      return { ...prev, [prop]: value };
    });
    setDisableBtn(false);
  };

  const handleExport = () => {};

  const handleSearch = () => {
    setDisableBtn(true);
  };

  return {
    settlementFilters,
    handleFilterChanges,
    disableBtn,
    handleSearch,
    handleExport,
  };
};
