import { GET_SPORT_REPORT } from "./actionType";

const initialState = {
  sportReport: [],
};

const sportReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPORT_REPORT:
      return {
        ...state,
        sportReport: action.payload,
      };
    default:
      return state;
  }
};

export default sportReportReducer;
