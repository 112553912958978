export const sportsArr = [
  { value: "All", label: "All" },
  { value: "Cricket", label: "Cricket" },
  { value: "Football", label: "Football" },
  { value: "Tennis", label: "Tennis" },
  { value: "Horse Racing", label: "Horse Racing" },
  { value: "Greyhound Racing", label: "Greyhound Racing" },
  { value: "Kabaddi", label: "Kabaddi" },
  { value: "Binary", label: "Binary" },
  { value: "Sports Book", label: "Sports Book" },
  { value: "Politics", label: "Politics" },
  { value: "Live Casino", label: "Live Casino" },
  { value: "Int Casino", label: "Int Casino" },
  { value: "Casino Vivo", label: "Casino Vivo" },
  { value: "iCasino", label: "iCasino" },
];

export const betTypeArr = [
  { value: "All", label: "All" },
  { value: "Non-Fancy", label: "Non-Fancy" },
  { value: "Fancy", label: "Fancy" },
];

export const stakeSelect = [
  { value: "Stake - Low to Up", label: "Stake - Low to Up" },
  { value: "Stake - Up to Low", label: "Stake - Up to Low" },
  { value: "Profit - Low to Up", label: "Profit - Low to Up" },
  { value: "Profit - Up to Low", label: "Profit - Up to Low" },
  { value: "Loss - Low to Up", label: "Loss - Low to Up" },
  { value: "Loss - Up to Low", label: "Loss - Up to Low" },
  { value: "Rate - Low to Up", label: "Rate - Low to Up" },
  { value: "Rate - Up to Low", label: "Rate - Up to Low" },
  { value: "Date - Low to Up", label: "Date - Low to Up" },
  { value: "Date - Up to Low", label: "Date - Up to Low" },
];

export const betHistoryColumns = [
  {
    id: "no",
    numeric: true,
    disablePadding: true,
    label: "NO",
    // width: "20%",
  },
  {
    id: "user",
    numeric: false,
    disablePadding: true,
    label: "USER",
    // width: "1%",
  },
  {
    id: "event",
    numeric: false,
    disablePadding: true,
    label: "EVENT",
    // width: "1%",
  },
  {
    id: "runner",
    numeric: true,
    disablePadding: true,
    label: "RUNNER",
    // width: "1%",
  },
  {
    id: "rate",
    numeric: true,
    disablePadding: true,
    label: "RATE",
    // width: "1%",
  },
  {
    id: "stake",
    numeric: true,
    disablePadding: true,
    label: "STAKE",
    // width: "15%",
  },
  {
    id: "dateTime",
    numeric: false,
    disablePadding: true,
    label: "DATE/TIME",
    // width: "1%",
  },
  {
    id: "profit",
    numeric: true,
    disablePadding: true,
    label: "PROFIT",
    // width: "1%",
  },
  {
    id: "loss",
    numeric: true,
    disablePadding: true,
    label: "LOSS",
    // width: "40%",
  },
  {
    id: "ip",
    numeric: false,
    disablePadding: true,
    label: "IP",
    // width: "40%",
  },
  {
    id: "commIn",
    numeric: false,
    disablePadding: true,
    label: "COMM IN",
    // width: "40%",
  },
  {
    id: "commOut",
    numeric: false,
    disablePadding: true,
    label: "COMM OUT",
    // width: "40%",
  },
];
