import React, { useState } from "react";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import { Button } from "@mui/material";
import { constants } from "../../constants/constants";
import SearchButton from "../../components/SearchButton";
import { sportsArr } from "../AccountStatement/constants";

const ProfitLossFilter = ({
  profitLossFilters,
  handleSearch,
  disableBtn,
  handleFilterChanges,
}) => {
  return (
    <div className="flex items-center gap-[20px] mb-[20px] bg-[white] p-[10px] border border-[#CC9647]">
      <div className="w-[20%]">
        <SearchInputs
          field={constants.selectField}
          label={constants.Sport}
          value={profitLossFilters.sports}
          onChange={(e) => handleFilterChanges("sports", e.target.value)}
          optionArr={sportsArr}
          id={"sport-select"}
        />
      </div>
      <div className="w-[20%]">
        <SearchInputs
          field={constants.dateField}
          label={constants.StartDate}
          value={profitLossFilters.startDate}
          onChange={(e) => handleFilterChanges("startDate", e)}
          id={"start-date"}
        />
      </div>
      <div className="w-[20%]">
        <SearchInputs
          field={constants.dateField}
          label={constants.EndDate}
          value={profitLossFilters.endDate}
          onChange={(e) => handleFilterChanges("endDate", e)}
          id={"end-date"}
        />
      </div>
      <SearchButton handleSearch={handleSearch} disableBtn={disableBtn} />
    </div>
  );
};

export default ProfitLossFilter;
