import React from "react";
import AnimatedInputField from "../../components/AnimatedInputField";
import { constants } from "../../constants/constants";
import { Controller, useForm } from "react-hook-form";
import ErrorField from "../../components/ErrorField";
import { error } from "../../constants/errors";
import { useClient } from "../../hooks/client-hook";

const CreateClientForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Name: "",
      Username: "",
      Password: "",
      RePassword: "",
    },
  });

  const { createClient } = useClient();

  const onSubmit = (data) => {
    createClient(data);
    reset({
      Name: "",
      Username: "",
      Password: "",
      RePassword: "",
    });
  };
  return (
    <form className="m-2 bg-white rounded-md" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 m-2">
        <div className="p-4 bg-white grid">
          <Controller
            name="Name"
            control={control}
            render={({ field }) => (
              <AnimatedInputField
                label="Name"
                type="text"
                register={register}
                validation={{ required: true }}
                errorCheck={errors.Name}
                {...field}
              />
            )}
          />
          {errors.Name && <ErrorField error={error.nameRequired} />}
        </div>
        <div className="p-4 bg-white grid">
          <Controller
            name="Username"
            control={control}
            render={({ field }) => (
              <AnimatedInputField
                label="Username"
                type="text"
                register={register}
                validation={{ required: true }}
                errorCheck={errors.Username}
                {...field}
              />
            )}
          />
          {errors.Username && <ErrorField error={error.usernameRequired} />}
        </div>
        <div className="p-4">
          <Controller
            name="Password"
            control={control}
            render={({ field }) => (
              <AnimatedInputField
                label="Password"
                type="password"
                register={register}
                validation={{ required: true }}
                errorCheck={errors.Password}
                {...field}
              />
            )}
          />
          {errors.Password && <ErrorField error={error.passwordRequired} />}
        </div>
        <div className="p-4">
          <Controller
            name="RePassword"
            control={control}
            render={({ field }) => (
              <AnimatedInputField
                label="RePassword"
                type="password"
                register={register}
                validation={{ required: true }}
                errorCheck={errors.RePassword}
                {...field}
              />
            )}
          />
          {errors.RePassword && <ErrorField error={error.passwordRequired} />}
        </div>
      </div>
      <div className="w-[10%] rounded-md">
        <button className="p-2 bg-[#CC9647] text-white m-[25px]">
          {constants.SUBMIT}
        </button>
      </div>
    </form>
  );
};

export default CreateClientForm;
