import React from "react";
import { WEEKLY_REPORT_SUCCESS } from "../redux/auth/actionType";
import { responseCode } from "../constants/responseCode";
import { endPoints } from "../services/EndPoints";
import { constants } from "../constants/constants";
import { logoutErrors } from "../constants/ErrorMessage";
import { toast } from "react-toastify";
import { _get } from "../services/HttpMethods";
import { useDispatch, useSelector } from "react-redux";
import { removeAuthData } from "../utils/common";

const useWeeklyReport = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const weeklyReport = useSelector((state) => state.auth.weeklyReport);

  const getWeeklyReport = async (params = null, data) => {
    try {
      // GET request to fetch weekly report data
      const response = await _get(endPoints.weeklyReport, params, token, data);
      if (!response) {
        return;
      } else if (response.status !== responseCode._200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: WEEKLY_REPORT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
      } else {
        toast.error(error.message);
      }
    }
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const currentDate = new Date();
  const dates = [];

  for (let i = 0; i <= 7; i++) {
    const pastDate = new Date(currentDate);
    pastDate.setDate(currentDate.getDate() - i);
    dates.push(formatDate(pastDate));
  }

  return { getWeeklyReport, weeklyReport, dates };
};

export default useWeeklyReport;
