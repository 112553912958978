import React from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import Sports from "../../components/Sports.js";
const Home = () => {
  return (
    <Layout heading="Home" marqueeText={`${commonMarqueeContent}`}>
      <Sports />
    </Layout>
  );
};

export default Home;
