import React, { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLogin } from "../../hooks/login-hook";
import { useNavigate } from "react-router-dom";
import { URLs } from "../../routes/URL";
import { sidebarArr } from "./constants";
import { constants } from "../../constants/constants";

const Sidebar = forwardRef(({ showSidebar, setShowSidebar }, ref) => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const { handleLogoutFunction } = useLogin();

  useEffect(() => {
    // Handler to close the dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowSidebar(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      {showSidebar && (
        <div
          className="w-[200px] bg-[#111111] absolute top-0 left-0 h-screen text-white cursor-pointer"
          ref={ref}
        >
          {sidebarArr.map((item, indx) => {
            return (
              <div
                className="flex items-center p-[15px]"
                style={{
                  backgroundColor: item.color || null,
                  borderBottom: "1px solid gray",
                }}
                onClick={
                  item.title === constants.LOGOUT
                    ? () =>
                        handleLogoutFunction(token, () => navigate(URLs.login))
                    : () => navigate(item.nav)
                }
              >
                <div>
                  <img
                    src={item.img}
                    alt={item.title}
                    width={"15px"}
                    height={"15px"}
                  />
                </div>
                <div className="pl-[10px] text-white font-light transition-all duration-900 font-bold">
                  {item.title}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
});

export default Sidebar;
