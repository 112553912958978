import React from "react";
import { FaStar } from "react-icons/fa";
import Event_B from "../../assets/B.png";
import Book from "../../assets/book.png";

const EventDetailsHeading = ({ titleName = "Match Odds" }) => {
  return (
    <div className="flex items-center justify-between bg-[#eee]">
      <div className="flex w-[30%] items-center">
        <div>
          <FaStar />
        </div>
        <div className="text-center text-black font-bold bg-primary py-1.5 px-1.5 relative mr-3 bg-[#CC9647]">
          {titleName}
        </div>
        <div className="w-0 h-0 border-b-[37px] -ml-3 border-b-[#CC9647] border-r-[35px] border-r-transparent"></div>
        <div>
          <img src={Event_B} alt="event_B" width="18px" height="18px" />
        </div>
      </div>
      <div className="flex w-[30%] items-center justify-around">
        <div>
          <img src={Book} alt="event_B" width="18px" height="18px" />
        </div>
        <div className=" font-semibold text-[12px]">
          {titleName === "FANCY" ? "No" : "BACK"}
        </div>
        <div className=" font-semibold text-[12px]">
          {titleName === "FANCY" ? "Yes" : "Lay"}
        </div>
      </div>
    </div>
  );
};

export default EventDetailsHeading;
