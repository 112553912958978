import React from "react";

const PageHeading = ({ title }) => {
  return (
    <div className="flex items-center justify-center p-[10px] text-xl border-b border-black border-solid">
      <em>
        <b>{title}</b>
      </em>
    </div>
  );
};

export default PageHeading;
