import React from "react";
import { useEditProfile } from "../../hooks/editProfile-hook";
import { _constants } from "./constant";
import Layout from "../../layout/Layout";
import { commonMarqueeContent, constants } from "../../constants/constants";
import SearchButton from "../../components/SearchButton";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";

const EditProfile = () => {
  const { formattedProfileData, clientData, setClientData, handleEditClient } =
    useEditProfile();

  const handleChange = (field) => (e) => {
    const value = field === "creditLimit" ? +e.target.value : e.target.value;
    setClientData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Layout heading="demo1[demo2]" marqueeText={commonMarqueeContent}>
      <div className="border p-6 rounded-md shadow-lg lg:mx-auto lg:container bg-white">
        <div className="flex items-center gap-[20px] mb-[20px]">
          <div className="w-[30%]">
            <SearchInputs
              field={constants.textField}
              label={constants.Name}
              value={clientData.name || ""}
              onChange={handleChange("name")}
            />
          </div>
          <div className="w-[30%]">
            <SearchInputs
              field={constants.textField}
              label={`${constants.creditLimit} ${constants.creditLimitLink}`}
              value={clientData.creditLimit || 0}
              onChange={handleChange("creditLimit")}
            />
          </div>
          <SearchButton
            btnTitle="SUBMIT"
            handleSearch={() =>
              handleEditClient({
                name: clientData.name,
                creditLimit: clientData.creditLimit,
              })
            }
          />
        </div>
      </div>
      <div className="bg-white border p-6 rounded-md shadow-lg mx-auto mt-8 lg:mx-auto lg:container">
        <h2 className="text-xl font-semibold border border-gray-300">
          <div className="text-base uppercase p-2.5 font-semibold">
            {_constants.EditProfile}
          </div>
        </h2>
        <ul className="border border-gray-300 rounded-sm overflow-hidden relative">
          {formattedProfileData.length > 0 &&
            formattedProfileData.map((item, index) => (
              <li
                key={item.title + index}
                className="flex items-center bg-white leading-6 p-2.5 px-5 m-0 border-b border-gray-300"
              >
                {item.icon}
                <span className="m-[10px]">{item.title} :</span> {item.value}
              </li>
            ))}
        </ul>
      </div>
    </Layout>
  );
};

export default EditProfile;
