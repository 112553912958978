import React from "react";

const EventDetailsFooter = ({ result = "Result" }) => {
  return (
    <div className="text-red-500 text-[12px] font-bold text-right pr-1.5 min-h-0 bg-[#eee]">
      {result}
    </div>
  );
};

export default EventDetailsFooter;
