const { getAuthData, setAuthData } = require("../../utils/common");
const {
  LOGIN_SUCCESS,
  CHANGE_PASSWORD_SUCCESS,
  PROFIT_LOSS_SUCCESS,
  WEEKLY_REPORT_SUCCESS,
} = require("./actionType");

const { token, userId, permission, role } = getAuthData();

const initialState = {
  isAuthenticated: !!token,
  token: token,
  errorMessage: null,
  userId: userId,
  userName: null,
  permission: permission,
  role: role,
  isPasswordChanged: false,
  ProfitLoss: [],
  weeklyReport: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      setAuthData({
        token: action.payload.token,
        userId: action.payload.userId,
        userName: action.payload.userName,
        permission: action.payload.permission,
        role: action.payload.role,
      });
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        userId: action.payload.userId,
        userName: action.payload.userName,
        errorMessage: null,
        permission: action.payload.permission,
        role: action.payload.role,
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordChanged: action.payload,
      };
    case PROFIT_LOSS_SUCCESS:
      return {
        ...state,
        ProfitLoss: action.payload,
      };
    case WEEKLY_REPORT_SUCCESS:
      return {
        ...state,
        weeklyReport: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
