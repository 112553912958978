import React from "react";
import Layout from "../../layout/Layout";
import { commonMarqueeContent } from "../../constants/constants";
import { useLocation } from "react-router-dom";
import WithdrawForm from "./WithdrawForm";

const Withdraw = () => {
  const location = useLocation();
  return (
    <Layout
      heading={`Bank Withdraw to ${location.state?.arr?.name}`}
      marqueeText={`${commonMarqueeContent}`}
    >
      <WithdrawForm
        clientData={location.state.arr}
        role={location?.state?.role || "master"}
      />
    </Layout>
  );
};

export default Withdraw;
