import React from "react";
import SearchInputs from "../../containers/SearchInputs/SearchInputs";
import { sortingArr } from "./constants";
import { FaSearch } from "react-icons/fa";
import SearchButton from "../../components/SearchButton";
import { constants } from "../../constants/constants";

const SearchClients = ({
  filters,
  handleChangeFilter,
  handleSearch,
  disableBtn,
}) => {
  return (
    <div className="flex items-center gap-[20px] mb-[20px]">
      <div className="flex items-center justify-center w-[3%] pt-[10px]">
        <FaSearch />
      </div>
      <div className="w-[30%]">
        <SearchInputs
          field={constants.textField}
          label={constants.SearchUsername}
          value={filters.username}
          onChange={(e) => handleChangeFilter("username", e.target.value)}
        />
      </div>
      <div className="w-[30%]">
        <SearchInputs
          field={constants.selectField}
          label={constants.Sorting}
          value={filters.sorting}
          onChange={(e) => handleChangeFilter("sorting", e.target.value)}
          optionArr={sortingArr}
          id={"sorting-select"}
        />
      </div>
      <SearchButton handleSearch={handleSearch} disableBtn={disableBtn} />
    </div>
  );
};

export default SearchClients;
